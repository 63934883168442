body { margin: 0; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; font-size: 12px !important; line-height: 1.5;} 

code { font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; } 

input, select { height: 45px !important; font-size: 12px !important; }
a, span { font-size: 12px !important; line-height: 1.5 !important; }


/* === My AC CSS === */ 
.my-ac-top-wrap { display: flex; flex-wrap: wrap; margin: 0 0 15px; } 
.my-ac-top-wrap .my-account-content { width: 70%; border: 0; } 
.my-ac-top-wrap .my-account-img-block { width: 30%; } 
.my-ac-top-wrap .my-account-img-block figure { border-radius: 50%; } 
.ac-status-top { margin-bottom: 15px; } 
.ac-status-top h2 { margin: 0 0 15px; } 
.ac-status-top ul { display: flex; flex-wrap: wrap; } 
.ac-status-top ul li { border: 1px solid #ccc; display: inline-flex; padding: 5px 25px 5px 12px; border-radius: 20px; margin: 0 3px 6px 0; font-size: 12px; position: relative; } 
.ac-status-top ul li.sts-live:after { content: ""; background: #008000; position: absolute; width: 10px; height: 10px; border-radius: 50%; right: 7px; top: 8px; } 
.ac-status-top ul li.sts-live { color: #008000; font-weight: 600; border-color: #008000; } 
.ac-status-top ul li.sts-vrfyd { background: url(assets/images/green_checkmark.png) no-repeat 95% center; background-size: 12px; } 
.ac-status-top ul li.sts-pending { background: url(assets/images/Red_X.png) no-repeat 93% center; background-size: 10px; } 
.ac-status-top + h2 { padding: 3px 0; } 
.my-account-buttons { padding: 20px; }
.maintenance-box { text-align: center; }
.maintenance-box h1 {color: #fff; padding: 35px 15px 35px; }
.my-account-buttons button { width: 100%; display: inline-block; padding: 17px; background: #4c164d; border: 0; color: #fff; font-size: 16px; line-height: 1; margin: 0 0 10px; cursor: pointer; border-radius: 45px; } 
.my-account-detail + .chat-bar { position: relative !important; } 
.my-account-buttons { padding: 20px; } 
.maintenance-box { text-align: center; } 
.maintenance-box h1 {color: #fff; padding: 35px 15px 35px; } 
.my-account-buttons button { width: 100%; display: inline-block; padding: 17px; background: #4c164d; border: 0; color: #fff; font-size: 16px; line-height: 1; height: 100%; cursor: pointer; border-radius: 45px; } .my-account-buttons .col-6 { margin-bottom: 10px; } 
.my-account-detail + .chat-bar ul li span { max-width: 120px; } 
.my-account-detail span.logout-btn { border: 2px solid #4c164d; padding: 8px 22px; border-radius: 50px; color: #4c164d; } 
/* === My AC CSS === */ 

.folder-box a { color: #000; } .gallery-img { margin: 0 0 30px; } 
.gallery-img .uploded-imges .col-6 { width: 25%; }

/* chat css */ 
.chat-box-wrap.content-library.my-folder .chat-bar { position: relative !important; } 
.chat-bar ul li span { max-width: 120px !important; } 
.content-library-box-list .content-library-box { width: calc(33% - 10px); } 
.content-library-box-list .content-library-box { width: calc(33% - 10px); } 
.content-library-box-list .content-library-box .library-inner .Title { font-size: 16px; } 
.content-library-box-list .content-library-box .library-inner .next-link:before { width: 9px; height: 9px; } 
.chat-detail.chat-middle p { padding-left: 0 !important; } 
.sent-msg, .receive-msg { max-width: 50% !important; } 
.modal-wrapper .form-group { display: flex; flex-wrap: wrap; padding-left: 18px !important; padding-right: 18px !important;} 
.model-box .radio-select { display: inline-flex !important; align-items: flex-start !important; position: relative; justify-content: flex-start !important; margin-bottom: 8px; width: 33%; padding: 0 5px; } 
/* chat css */

.content-library-box-list .content-library-box .library-inner { position: relative; padding: 8px; } 
.content-library-box-list .content-library-box .library-inner .Title { color: #000; } 
.content-library-box-list .content-library-box .library-inner .Title:before { display: none; } 
.content-library-box-list .content-library-box span.next-link:before { border-color: #000; } 
.content-library-box-list .content-library-box figure::after { opacity: 0; } 
.chat-box-wrap.content-library.my-folder .content-library-box-list { justify-content: center; gap: 15px; } 
.wrapper.main-content .right-block .txt-format { text-align: center; } 
.camera-btn { background: url(https://img.icons8.com/ios/50/000000/switch-camera.png); background-position: center; background-size: 40px; background-repeat: no-repeat; width: 20px; height: 20px; padding: 25px; } 
.bhAZdn { position: fixed; right: 0px; width: 100%; bottom: 0; background: rgba(0, 0, 0, 0.8); z-index: 10; display: flex; align-items: center; justify-content: space-between; padding: 8px; box-sizing: border-box; flex-direction: column-reverse; } 
.imagecamera { width: 400px; height: 400px; } 
.heading-block h1 { margin: 0 auto; } 
.text-center { text-align: center; } 
.age-verif-block h1 { margin: 0 0 15px; text-transform: uppercase; } 
.selfie-image .image-file { padding: 2px 0; } 
.profile-photo-rounded { width: 220px; height: 220px; margin: 0 auto 20px; position: relative; border-radius: 50%; overflow: hidden; border: 5px solid #fff; box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); } 
.profile-photo-rounded img { width: 100%; height: 100%; object-fit: cover; } 
.profile-photo-rounded.sqr { border-radius: 10px; width: 320px; height: 185px; } 
.acent-btn { background-color: #4c164d; color: #ffffff; font-family: "Gothic A1", sans-serif; font-size: 16px; font-weight: 700; line-height: 20px; letter-spacing: 0.01em; text-align: center; border: 0; padding: 15px 35px; border-radius: 35px; cursor: pointer; }
  /* ------- Picture and Videos page ---------*/
.folder-block { padding: 20px; } 
.folder-block h2 { margin: 0 0 20px; } 
.folder-block .col-4 { width: 33.33337%; padding: 0 7.5px; } 
.folder-box { margin: 0 0 15px; position: relative; cursor: pointer; } 
.folder-box .folder-image { margin: 0 0 12px; } 
.folder-box .folder-image img { width: 100%; } 
.form-inner-holder-info.for-add-more { text-align: left; position: relative; padding-right: 60px; margin-bottom: 15px; } 
.floating-btn { position: absolute; right: 0; top: 0; width: 45px; height: 45px; z-index: 3; } 
.floating-btn a { background: #4c164d; padding: 10px; border-radius: 50%; display: inline-flex; align-items: center; justify-content: center; height: 45px; box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); } 
.floating-btn a:after { content: "+"; position: absolute; top: 54%; left: 50%; transform: translate(-50%, -50%); font-weight: 900; font-size: 20px; line-height: 1; }
.selfie-image .image-file p { margin: 0 0 15px; } 
.discliamer-text { padding-top: 15px; }
.content-account ul li { margin-bottom: 15px; } 
.page-heading-block { padding: 0 20px 20px; } 
.page-heading-block.stex h2 { margin: 0 0 10px; } 
.upe h3 { padding: 14px 0; font-size: 12px; text-align: left; } 
.upe .col-3 { width: 30%; padding: 0 7.5px; }
.upe .col-7 { width: 70%; padding: 0 7.5px; }

.form-inner-holder { border: 1px solid #ccc; padding: 10px; margin-bottom: 15px; border-radius: 15px; text-align: center; }
.setup-profile-wrap .my-ac-form-info .form-inner-holder {  margin-bottom: 0px !important; }
.form-inner-holder-info { padding: 0 0 20px; }

.floating-btn.price { right: 75px; }
.floating-btn.price a::after { content: "$"; font-size: 14px; }
.folder-block .row { justify-content: center; }
.private-box:after { content: "$"; position: absolute; top: 106px; left: 50%; transform: translate(-50%, -50%); font-weight: 800; font-size: 5vw; line-height: 1; color: #4c164d; }
.my-ac-form-info { padding: 0 20px 20px; }
.setup-profile-wrap .my-ac-form-info { padding: 0 100px 10px; margin-top: 20px; }

.profile-img-seprte { padding: 0 20px; }
.inner-uploaded { height: auto !important; cursor: pointer;}

.folder-box .delete-folder { background: #f00; color: #fff; width: 24px; height: 24px; border-radius: 50%; position: absolute; right: 0; top: 0; z-index: 3; border: 1px solid #fff; box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); line-height: 0.5; font-weight: 700; cursor: pointer; }
.consumer-onboarding-bottom.card-info p { margin: 0 0 20px; font-size: 16px; line-height: 1.5; font-weight: 500; border: 1px solid #ccc; border-radius: 10px; padding: 10px; }
.new-card-form p.info.text-center { text-align: center; margin-bottom: 10px; }
.new-card-form p.info.text-center { text-align: center; margin-bottom: 10px; }

.add-money-account { max-width: 450px; margin: 0 auto 20px; }
.add-money-account .card-detail .card-holder-name { border-bottom: 1px dashed #ccc; padding-bottom: 12px; }
.add-money-account .card-detail .card-holder-name h4 { text-transform: uppercase; }

.multiple-card { display: flex; flex-wrap: wrap; gap: 4px; padding: 0 !important; }
.multiple-card .add-money-account { max-width: 48%; width: 48%; }
.multiple-card .add-money-account.active-card { border-color: #4c164d; }

.plan-wrap { padding: 16px 20px 16px; }
.paln-top h2 { font-size: 24px; }
.plan-left-block p { font-size: 16px; line-height: 1.2; }

.videos-photos-block .videos-photos-item label { word-break: break-word; }

h4.cagg { padding: 2px 0; }

.consumer-info-text { text-align: left;}
.consumer-info-text p { margin: 0 0 15px;}
.consumer-info-text ul { list-style: disc; margin: 0 0 15px; padding: 0 0 0 14px;}
.consumer-info-text.mt-5 { margin-top: 20px;}

.consumer-info-text .btn { background-color: #4C164D; color: #FFFFFF; font-family: 'Gothic A1', sans-serif; font-size: 16px; font-weight: 700; line-height: 20px; letter-spacing: 0.01em; text-align: center; height: 52px; width: 100%; border-radius: 26px; border: none; padding: 5px 24px; display: flex; align-items: center; justify-content: center; cursor: pointer; box-shadow: 0px 10px 30px 2px rgba(76, 22, 77, 0.1); max-width: 375px; margin: 20px auto; }


.agelaw span.confirm label { font-weight: 500; }

.model-inner { padding: 36px 15px !important; }

.upload-process { margin-bottom: 12px; }
.uploaded-btn-block { padding: 12px 15px 0; }

.each-blur-optn .blur-image { align-items: start; }

.my-ac-form-info.pad-rmv { padding: 0px; }

.my-ac-form-info.reg-nt, .my-ac-form-info.log-nt { padding-left: 0; padding-right: 0; }
.my-ac-form-info.reg-nt p, .my-ac-form-info.log-nt p { margin: 0; font-size: 12px; line-height: 1.5;}


form.reg-horizontal-line { position: relative; }

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { -webkit-appearance: none; -moz-appearance: none; appearance: none; margin: 0; }


.chat-box-wrap.content-library p { white-space: normal; width: 100%; max-width: 100%; font-size: 12px !important; line-height: 1.4 !important;  word-break: break-word; padding-left: 0 !important; padding-right: 0 !important;}

.chat-box-wrap.content-library .chat-detail.chat-time p { padding: 0; font-size: 12px; text-align: right; line-height: 1.5; }
.m-0 {margin: 0!important;}

.payment-benefits-list { max-width: 550px; margin: 0 auto; }

.card-number-block.single-content-radio { justify-content: flex-end; }
.my-wallet .right-block .icon-box { background: #fff; border-radius: 50%; padding: 15px 12px 10px 12px; width: 70px; height: 70px; box-sizing: border-box; }
.my-account-detail-item .left-content .img-icon { width: 26px;}


  /*------ 14.05.2024 Fixing ------*/
.mb-15 { margin-bottom: 15px; }
.my-account-buttons { padding-top: 0; }
.setup-profile-wrap.search-wrap .gallery-img#scrollableDiv { min-height: 186px; }

.benfits-block h1 { padding-right: 35px; }
.benfits-block .heading-block h1 { width: 100%; }
.benfits-block > .heading-block > .back-btn { flex:0 0 35px; height: 35px; }

.agelaw span.confirm { align-items: flex-start; }
.agelaw span.confirm input { flex:0 0 12px; margin-right: 10px; padding: 0; height: 12px !important; }
.profile-states input { margin: 0; margin-right: 7px; padding: 0; height: auto !important; }


.request-purchased-wrap .heading-block h1 { width: 100%; }

.chat-box-wrap .chat-type-in { width: 100%; }
.container.folder-block {padding: 0;}
.folder-block .heading-block h1 { margin-left: 0; }

/* .chat-bar,
.container.folder-block .chat-bar, 
.my-account-detail + .chat-bar,
.chat-box-wrap.content-library.my-folder .chat-bar  { position: sticky !important; background-color: #FFF; bottom: 0;    } */

.wrapper .my_account-wrap, .wrapper .chat-box-wrap.content-library.my-folder { padding-bottom: 0; }
.chat-items { min-height: 300px;}
.heading-block h1 { margin-left: 0; }

.folder-box .delete-folder {padding: 0;}
span.add-link { max-width: 40px; margin-right: 15px; }

.heading-block .profile-option-dot { font-size: 18px; }
  /*------ 14.05.2024 Fixing ------*/

.creator-benefits .consumer-info-text1 ul li:last-child { margin: 0; }
.creator-benefits .consumer-info-text1 ul { margin-bottom: 0px; }
.pointer { cursor: pointer;}

.files-to-edit-del { overflow: visible !important; padding-top: 12px; }
.files-to-edit-del .image-box { position: relative; padding: 0 0 56.25%; }

i.edit-icon { margin-left: auto; cursor: pointer; }
i.add-edit-icon { margin-left: auto; cursor: pointer; }

.files-to-edit-del .image-box figure, .files-to-edit-del .image-box video { padding: 0; position: absolute; top: 0; right: 0; bottom: 0; left: 0; width: 100%; height: 100%; object-fit: cover; border-radius: 10px; cursor: pointer; }
.files-to-edit-del .image-box figure::after { display: none; }
.files-to-edit-del .delete-icon { position: absolute; right: -10px; top: 2px; z-index: 3; }

.files-to-edit-del .delete-icon button.delete-folder { border: 0; background: #f00; color: #fff; width: 22px; height: 22px; border-radius: 50%; font-size: 14px; font-weight: 600; cursor: pointer; }
.delete-popup h4 { margin: 0 0 10px; font-size: 18px; }

.delete-popup button { background: #901893; border: 0; color: #fff; padding: 10px 22px; border-radius: 25px; margin: 0 5px; line-height: 1; cursor: pointer; }

.delete-popup h6 { margin: 0 0 10px; font-size: 14px; font-weight: normal; }

.delete-popup button + button { background: #7f7f7f; }

.creator-benefits p { margin: 0 0 20px; }

.consumer-onboarding-bottom { text-align: center;}
.consumer-onboarding-bottom .btn-block { max-width: 45%; display: inline-block; margin: 0 10px 10px; }

.consumer-onboarding-wrap .logo-block img { max-width: 180px; }
.consumer-onboarding-wrap .creator-benefits { margin: 0 0 60px; }
.consumer-onboarding-wrap .creator-benefits p { margin: 0; }

.consumer-onboarding-wrap .consumer-onboarding-bottom{ padding-top: 0px; }

ul.profile-feature-list { display: flex; flex-wrap: wrap; }

ul.profile-feature-list li { color: #140a1b; font-size: 15px; font-weight: 500; letter-spacing: .01em; line-height: 1.4; margin-bottom: 20px; padding-left: 36px; position: relative; text-align: left; width: 50%; padding-right: 10px; }

.cusmr-bnft-form { padding: 20px !important; border: 1px solid #ccc; border-radius: 20px; }
.cusmr-bnft-form .new-user { padding: 0 0 15px; margin-top: 0 !important; }
.cusmr-bnft-form input.submit { margin-top: 15px; }
.cusmr-bnfts-top-info ul { margin: 0 0 15px; }

.text-center.mts { margin-top: 20px !important; }

i.trash-icon { cursor: pointer;}
.add-money-filed {background-position: left 0 center !important; padding-left: 0 !important; background-image: none !important;}

.add-money-filed  input.add-money { background-image: url('/src/assets/images/dollar-symbol.png'); background-position: left 15px center !important; background-repeat: no-repeat; }

.show-on-mob { display: none !important;}

.pointer{cursor: pointer;}
.bellContainer{ position: 'relative'; cursor: 'pointer' }
.badge { position: absolute; top: 1px; margin-left: -19px; background: #4C164D; color: white; border-radius: 50%; padding: 4px 8px; font-size: 12px; }

.unreadMessage .chat-detail.chat-middle p { font-weight: 600 !important; color: #111 !important; }
.unreadMessage .chat-detail.chat-time p {color: #f2c1f9;}
.unreadMessage .chat-detail.chat-time p + p { color: #CA4AB5; }

/* custom style 2 */

.btn-switch { font-size: 1em; position: relative; display: inline-block; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }
.btn-switch__radio { display: none; }
.btn-switch__label { display: inline-block; padding: .75em .5em .75em .75em; vertical-align: top; font-size: 12px; font-weight: 700; line-height: 1; color: #666; cursor: pointer; transition: color .2s ease-in-out; }
.btn-switch__label + .btn-switch__label { padding-right: .75em; padding-left: 0; }
.btn-switch__txt { position: relative; z-index: 2; display: inline-block; min-width: 1.5em; opacity: 1; pointer-events: none; transition: opacity .2s ease-in-out; font-size: 10px; }
.btn-switch__radio_no:checked ~ .btn-switch__label_yes .btn-switch__txt,
.btn-switch__radio_yes:checked ~ .btn-switch__label_no .btn-switch__txt { opacity: 0; }
.btn-switch__label:before { content: ""; position: absolute; z-index: -1; top: 0; right: 0; bottom: 0; left: 0; background: #f0f0f0; border-radius: 1.5em; border: 1px solid #ddd; box-shadow: inset 0 .0715em .3572em rgba(43,43,43,.05); transition: background .2s ease-in-out; }
.btn-switch__radio_yes:checked ~ .btn-switch__label:before { background: #000; }
.btn-switch__label_no:after { content: ""; position: absolute; z-index: 2; top: .5em; bottom: .5em; left: .7em; width: 2em; background: #fff; border-radius: 1em; border: 1px solid #ddd; pointer-events: none; box-shadow: 0 .1429em .2143em rgba(43,43,43,.2), 0 .3572em .3572em rgba(43,43,43,.1); transition: left .2s ease-in-out, background .2s ease-in-out; }
.btn-switch__radio_yes:checked ~ .btn-switch__label_no:after { left: calc(100% - 2.5em); background: #fff; } 
.btn-switch__radio_no:checked ~ .btn-switch__label_yes:before, .btn-switch__radio_yes:checked ~ .btn-switch__label_no:before { z-index: 1; } 
.btn-switch__radio_yes:checked ~ .btn-switch__label_yes { color: #fff; } .btn-switch__radio_yes:checked ~ .btn-switch__label span.btn-switch__txt { color: #fff; }


.swiper-button-prev:after, .swiper-button-next:after { color: #fff;}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet { background: #fff; }

.new-page-back { padding: 20px; }

.profile-content .pointer svg path { fill: #ca49b5; }
.profile-content .pointer svg { width: 16px; height: 16px; margin-left: 5px; }

span.filter-link { background-color: #fff;}

.search-filter-slide-inner { height: 70%; overflow-y: auto; }

h2.c-usename { display: flex; flex-wrap: wrap;}
h2.c-usename .Share-link { padding-left: 10px; padding-top: 2px; }
.filter-block .fiter-icon { background-color: #fff; }

h3.share-link { display: flex; align-items: center; margin: 0 0 10px; }
h3.share-link a.pointer { padding-top: 2px; }

.my-account-buttons h3.share-link a.pointer { padding-left: 5px; }


.notification-bell.bellContainer { position: relative; }
.notification-bell.bellContainer svg { width: 25px; height: 25px; }
.notification-bell.bellContainer svg path { fill:#982c9a; }
.notification-bell.bellContainer span.notification-bell { width: 20px; height: 20px; margin: 0; top: -4px; right: -10px; padding: 0; font-size: 10px; line-height: 1; display: inline-flex; align-items: center; justify-content: center; background: #952a98; }

.profile-content {padding-right: 90px !important;}
.gallery-img, .setup-profile-wrap.search-wrap .gallery-img#scrollableDiv{ padding-top: 20px!important;}

.notification-switch .hdr-switch { display: flex; align-items: center; padding: 6px 0; } 
.notification-switch .hdr-switch h3 { margin-right: 10px; } 
.notification-switch .hdr-switch span.btn-switch label::after { width: 1.8em; } 
.notification-switch .hdr-switch span.btn-switch label { padding-top: 5px; padding-bottom: 5px; }
.notification-switch .hdr-switch span.btn-switch label span.btn-switch__txt { opacity: 1; }

.notification-switch .btn-switch__radio_yes:checked ~ .btn-switch__label:before { background: #4c164d;}

.notification-switch.for-consumer {padding-bottom: 15px;}
.notication-txt { margin-top: 15px;}

.view-wrap .total-view {padding: 4px 0;}


.pass-input span { top: 12px !important; }

.form-block .new-user { font-size: 12px !important; }

.static-content h1 { font-size: 22px !important; }
.static-content p { font-size: 14px !important; line-height: 1.5 !important; }

.blur-image input { height: auto !important; }

.model-box input[type="radio"] { height: 16px !important; }

.receive-msg, .sent-msg { font-size: 12px !important; line-height: 1.4 !important; }

.add-new-address-inner p.info { font-size: 12px !important; line-height: 1.4 !important; }

.withdraw-amoun-right .amount-block input[type="number"] { height: auto !important; }

.my-account-buttons .row.creator {justify-content: center;}
.upload-content p { color: #000;}
.upload-content span { color: #fff !important; }
.upload-file-tab input[type="file"] { cursor: pointer; }

.setup-profile-wrap .my-ac-form-info + .text-center.mts { padding-bottom: 10px; }

.chat-btn-block span.option-btn { display: inline-flex; align-items: center; justify-content: center; }
.chat-btn-block span.Pay-btn { color: #fff !important;}

.video-photo-filter .search-filter-slide-inner { height: 30%; }

.profile-edit-content .edit-btn { padding: 8px 14px 6px !important; }

.data-filter-form .btn-block {margin-top: 0 !important;}
.data-filter-form .btn-block input.submit {margin-top: 10px !important; margin-bottom: 10px !important;}

.no-data-found { text-align: center; padding: 15px 20px;}

.searh-block{ width: 90%; margin-bottom: 0 !important;margin-left:0 !important;}


.register-btn-search span.submit.hdr-btn {max-width: inherit;width: auto; display: inline-block;padding: 12px 20px;}
.otp-verify input { width: 14%; margin: 0px 8px; text-align: center;padding: 0 10px; font-size: 15px !important;}
.otp-verify .form-group { text-align: center; }

.chat-list, .videos-photos-item { cursor: pointer;}

.creator-benefits ul li strong { display: block; padding-bottom: 3px; }
.lightbox-class { position: absolute; z-index: 9999; }
.lightbox-class img { padding: 30px;}
.receive-msg.more-content.img-receive img { cursor: pointer;}

.accordion { border: 1px solid #ccc; border-radius: 5px; overflow: hidden; }

.accordion-item { border-bottom: 1px solid #ccc; }
.accordion-title { display: flex; justify-content: space-between; padding: 10px 15px; cursor: pointer; }
.accordion-title:hover,.active .accordion-title {  background-color: #4C164D; }
.accordion-content {  max-height: 0; overflow: hidden; padding: 0 15px; }
.accordion-content.open { max-height: 130px;  padding: 10px 15px; overflow-y: auto; }

.creator-benefits .accordion-title .acrd-ttl-lt { display: flex; align-items: center; font-weight: 600; width: calc(100% - 20px);}
.creator-benefits .accordion-item.active .acrd-ttl-lt img, .creator-benefits .accordion-title:hover .acrd-ttl-lt img { filter: brightness(10) contrast(10);}
.creator-benefits .accordion-title .pls-mins { font-weight: 600; font-size: 16px; line-height: 1; width: 20px; height: 20px; background: #fff; color: #000; display: inline-flex; align-items: center; justify-content: center; border-radius: 50%; font-family: Arial; }
.creator-benefits .accordion-title .pls-mins img { width: 16px; }
.new-data .accordion { margin-bottom: 15px; border-radius: 15px; }
.ReactTags__suggestions { width: 98%; text-align: left; height: 200px; overflow-y: auto; padding-left: 15px; z-index: 9; }
.ReactTags__suggestions ul { background-color: #deb3ec !important; border-radius: 10px !important; padding-top: 6px !important; padding-bottom: 6px !important; }
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {background: rgba(230, 225, 231, 1) !important;  border-radius: 20px;}
.ReactTags__suggestions ul li { border: 0 !important;}
.mesg-info-heading { text-align:center; margin-bottom: 5px; }


.my-wallet .right-block .icon-box.withdraw-btn { width: auto; height: auto; border-radius: 35px; display: inline-flex; align-items: center; padding: 8px 25px; } 
.my-wallet .right-block .icon-box.withdraw-btn figure { max-width: 40px; height: 40px; margin-left: 10px; }

.drop-menu { position: relative; }
.drop-menu .more-options { position: absolute; background: #901893; min-width: 120px; right: 0; top: 15px; padding: 10px; text-align: left; border-radius: 5px; }
.drop-menu .more-options ul { list-style: none; } 
.drop-menu .more-options ul li { padding: 7px 5px; border-bottom: 1px solid #fff; cursor: pointer; } 
.drop-menu .more-options ul li:last-child { border-bottom: 0; }
.drop-menu .more-options ul li:hover { background: #4C164D;}
.progress .bar { min-width: 0; border-radius: 0;}

.compress-wrap { display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center; width: 100%; } 
.compress-wrap .save-btn-icon {width: 55%; display: flex ; justify-items: center; justify-content: space-between; margin: 0px 7px 0 0px;}
.compress-wrap button.submit { width: 100%; max-width: 215px; margin: 0; font-size: 12px; }
.compress-wrap button.submit:nth-child(2) { margin: 0 0 0 5px }
.compressionlevel { width: 35%; } 
.compressionlevel input { padding: 0 !important; height: 6px !important;}
.compressionlevel > div { display: flex; align-items: center; justify-content: space-between; } 
.compressionlevel > div span { font-size: 10px !important; display: inline-block; padding-top: 4px; margin: 0 !important; }


.progress .percent { right: -56px !important;}

.upload-rt-wrap { width: 100%; display: flex; justify-content: space-between; padding-bottom: 10px; } 
.upload-rt-wrap .upload-detail-left { width: calc(100% - 90px); padding-right: 10px; } 
.upload-rt-wrap .btn-icon { width: 90px; display: flex; align-items: center; justify-content: flex-end; }
.upload-rt-wrap .btn-icon button { background: #4C164D; border: 0; width: 40px; height: 40px; border-radius: 50%; display: inline-flex; align-items: center; justify-content: center; cursor: pointer; margin: 0 3px;}
.upload-rt-wrap .btn-icon button img {filter: brightness(1) invert(1); width: 18px;}
.upload-rt-wrap .btn-icon button:disabled {opacity: 0.3; pointer-events: none;}

.upoad-detail h5 { -webkit-line-clamp: 2; text-overflow: ellipsis; overflow: hidden; display: -webkit-box;  -webkit-box-orient: vertical;}

.signup-btn-wrap { display: flex; align-items: center; justify-content: center; } 
.signup-btn-wrap span.submit { width: auto; margin: 0 10px; padding: 12px 35px; height: auto; }

.setup-profile-wrap.search-wrap .my-ac-form-info .form-inner-holder { font-size: 13px; }
.automate-message .upe textarea { height: 200px;}
.main-content .maintenance h3 { margin: 20px auto 17px; padding: 0; }
.main-content .maintenance .right-block { padding:0 !important;}
.main-content .maintenance .main-inner { max-width: 600px !important;}

/* =========== THEME DARK CSS ==========*/
body.dark-mode span.badge {  color: white; }
body.dark-mode .model-box .radio-select label { color: #fff; }
body.dark-mode .modal-wrapper .model-inner { background-color: #161616; color: #fff;box-shadow: 0px 0px 25px #a070a1b3; max-width: 450px; }
body.dark-mode .unreadMessage .chat-detail.chat-middle p { font-weight: 600 !important; color: #fff !important; }
body.dark-mode .header { background: #000; border-bottom-color: #000; z-index: 999 !important; } 
body.dark-mode .header .desktop-logo img { filter: brightness(2); } 
body.dark-mode .container>div { background-color: #000; color: #fff; } 
body.dark-mode .top-arrow img { filter: brightness(0) invert(1); }

body.dark-mode .profile-content p { color: #fff; }

body.dark-mode input.search-box { color: #4C164D;}
body.dark-mode a, body.dark-mode span, body.dark-mode u { color: #982c9a; }
body.dark-mode input.submit, body.dark-mode .submit {color: #fff; }
body.dark-mode input, body.dark-mode select, body.dark-mode .data-filter-form .datepicker input, body.dark-mode textarea { color: #4C164D;}

body.dark-mode .static-content u { color: #982c9a !important;}

body.dark-mode .model-box .radio-select label::before { border-color: #982c9a;}

body.dark-mode .container div.ReactTags__tagInput input.ReactTags__tagInputField, body.dark-mode .container div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
 color: #4C164D; }

body.dark-mode .container div.ReactTags__selected span.ReactTags__tag { background-color: rgb(215 215 215); }

body.dark-mode input::-moz-placeholder { color: #4C164D; opacity: 1; }
body.dark-mode input:-ms-input-placeholder { color: #4C164D; }
body.dark-mode input::-webkit-input-placeholder { color: #4C164D; }

body.dark-mode textarea::-moz-placeholder { color: #4C164D; opacity: 1; }
body.dark-mode textarea:-ms-input-placeholder { color: #4C164D; }
body.dark-mode textarea::-webkit-input-placeholder { color: #4C164D; }

body.dark-mode .search-filter-slide-inner { background-color: #161616; }
body.dark-mode .price-range-slider .range-value input { background: #fff; }

body.dark-mode .logo-block img { filter: brightness(2);height: 167px; }
body.dark-mode .agelaw label { color: #fff; }
body.dark-mode .form-block .new-user { color: #fff; }

body.dark-mode .heading-block h1, body.dark-mode .creator-benefits h3, body.dark-mode .creator-benefits ul li { color: #fff; font-size: 12px; line-height: 1.5;}
.creator-benefits ul li { margin-bottom: 15px; }
body.dark-mode ul.profile-feature-list li { color: #fff;}
body.dark-mode ul.profile-feature-list li .list-icon img {filter: brightness(2);}
body.dark-mode .my-account-detail span.logout-btn, body.dark-mode section.header span.log-out-btn { border: 2px solid #9C27B0; color: #9C27B0; }
body.dark-mode  h2.c-usename .Share-link svg path { fill: #9C27B0;}

body.dark-mode .my-account-detail-item .item-name { color: #fff;}
body.dark-mode .my-account-detail-item .left-content .img-icon { filter: brightness(2); }
body.dark-mode span.right-arrow img { filter: brightness(2); }

body.dark-mode .data-filter-block { background-color: #161616; }
body.dark-mode .data-filter-block h3 { color: #fff; }

.my-account-buttons h3.share-link a.pointer svg path { fill: #CA4AB5; }


body.dark-mode .chat-bar ul li span.active img { filter: brightness(2); }
body.dark-mode .receive-msg {color: #4C164D;}
/* body.dark-mode .chat-type-form input[type="text"] {color: #fff;} */
body.dark-mode .video-photo-filter-item figure.icon img { filter: brightness(2); }
body.dark-mode .video-photo-filter-item .left-block label { color: #fff; }

body.dark-mode .add-money-account .card-detail .card-holder-name h4 { color: #ccc; }
body.dark-mode .card-number-block p.card-number span { color: #ccc; }
body.dark-mode .card-number-block .card-number .show { color: #ccc; }

body.dark-mode .card-detail>label, body.dark-mode .card-holder-name>label, body.dark-mode .address-detail .left-block label { color: #ccc; }
body.dark-mode .withdraw-amoun-right .amount-block { display: flex; color: #4c164d; opacity: 0.8; }
body.dark-mode .payment-type-item p { color: #982c9a; }


body.dark-mode .profile-edit-content .edit-btn { color: #fff; background: #161616; }
body.dark-mode .add-money-heading h3, body.dark-mode .address-heading h3 { color: #982c9a;}

body.dark-mode .pass-input span { filter: brightness(0) contrast(10); }

body.dark-mode .delet-content { color: #fff; }

body.dark-mode .notification-switch .hdr-switch span.btn-switch label.btn-switch__label:before { background: #000; } 
body.dark-mode .notification-switch .hdr-switch span.btn-switch label span.btn-switch__txt { color: #fff !important;}

body.dark-mode .content-library-box-list .content-library-box .library-inner .Title {color: #fff;}
body.dark-mode .content-library-box-list .content-library-box span.next-link:before {border-color: #fff;}
.submit.hdr-btn { font-size: 12px; line-height: 1; height: auto; padding: 12px 15px; margin-left: 12px; margin-bottom: 5px;
  margin-top: 5px; }

.hdr-rt { display: flex; align-items: center; padding-right: 15px; }

.chat-detail.chat-middle p img { filter: brightness(10) contrast(0); }
.browse-block input[type=file], .inner-uploaded input[type=file] { height: 100% !important; }

.successful-img img {
  height: auto !important;
}
span.filter-link { margin-right: 10px;}
/* =========== THEME DARK CSS ==========*/

/* -=-=-=-=-=-=-= MEDIA QUERY / RESPONSIVE CSS -=-=-=-=-=-=-= */
@media (max-width: 768px) {
  
  

  body.dark-mode section.footer { background-color: #161616; }
  body.dark-mode .copyright p, body.dark-mode section.footer ul span { color: #fff;}
  body.dark-mode .chat-bar { background-color: #161616; }
  .setup-profile-wrap .my-ac-form-info { padding: 0 20px; }
}

@media (max-width: 767px) {
  .main-content .maintenance h3 { margin: 17px auto 17px; }
  .main-content .maintenance h2 { margin: 0px auto 17px;}
  .automate-message .upe textarea { height: 300px; }
  .compress-wrap button.submit:nth-child(2) { margin: 5px 0 0 0 }
  .compress-wrap .save-btn-icon {width: 100%; display: block ; }
  .setup-profile-wrap.search-wrap .gallery-img#scrollableDiv {max-height: calc(100vh - 153px);}
  .is-hidden-mob {display: none !important; }
  span.filter-link { margin-right: 5px; width: 46px !important;}
  .header-container { padding-right: 12px; }
  .header { position: relative !important;}
  .my-ac-top-wrap { flex-direction: column-reverse; padding: 15px 0 0; }
  .text-center.mts { margin-top: 20px !important; }
  .my-ac-top-wrap .my-account-content,
  .my-ac-top-wrap .my-account-img-block { width: 100%; }
  p.reg-main-txt { padding: 0 !important; }
  .ac-status-top h2, 
  .ac-status-top + h2 { text-align: center !important; }
  .ac-status-top ul { justify-content: center; }

  span.chat-tab { bottom: 10px !important; z-index: 99999; }

  .content-library-box-list { display: flex; flex-wrap: wrap; gap: 20px; }
  .content-library-box-list .content-library-box { width: calc(50% - 10px); }
  .content-library-box-list .content-library-box .library-inner { padding: 12px 18px; }

  .content-library-box-list .content-library-box .library-inner .Title:before { display: none; }

  .model-box .radio-select { width: 100%; padding: 0; }

   
  .save-btn { padding-top: 25px; }

  .my-account-buttons button { font-size: 12px; padding: 10px 15px; line-height: 1.1; }
  .gallery-img .uploded-imges .row .col-6 { width: 50%; }

  .private-box:after { top: 42%; font-size: 14vw; }
  .consumer-onboarding-wrap .row .col-6 { width: 50%; }

  .setup-profile-wrap.photo-video-wrap.full-pages .row .col-6 { width: 33.333%; }
  form.reg-horizontal-line:after { content: ''; background: #ccc; height: 1px; position: absolute; top: 55px; right: 0; left: 0; }
  

  /*------ 14.05.2024 Fixing ------*/
  .my-account-detail + .chat-bar {  position: sticky !important; }
  .chat-bar { position: sticky; left: 0; right: 0; bottom: 0; background-color: #FFF; z-index: 2; } 
  body .container.folder-block .chat-bar { position: sticky; }

  .search-filter-slide { position: fixed !important; }
  .form-inner-holder .col-6 { flex:0 0 100% }
  
  .xx .my-account-detail {min-height: calc(100vh - 330px);}
  .registration-wrap form.reg-horizontal-line:after { display: none;}

  .consumer-onboarding-wrap { padding-bottom: 80px !important; }

  .folder-block.text-center .form-inner-holder-info {text-align: left;}

  .chat-bar ul li { flex:0 0 25% !important; padding-left: 5px; padding-right: 5px; }
  .chat-bar ul li h5 { font-size: 10px; line-height: 1.2; margin-bottom: 0; display: none;}

  .sent-msg, .receive-msg { max-width: 80% !important; padding: 15px 18px !important;}
  .chat-box-wrap .chat-type-box { position: sticky; bottom: 0; background: #FFF; }
  
  /*------ 14.05.2024 Fixing ------*/

  .my-ac-top-wrap .my-account-img-block figure { border-radius: 50%; width: 200px; height: 200px; padding: 0; margin: 0 auto; }

  .video-photo-filter-block { height: 55vh; overflow-y: auto; overflow-x: hidden; }

  .consumer-onboarding-bottom .btn-block { margin: 0 0 5px !important; max-width: 100%; }
  .consumer-onboarding-bottom .btn-block span.submit { margin: 5px auto; }
  .consumer-onboarding-wrap .creator-benefits { margin: 0 0 110px; }

  ul.profile-feature-list li { width: 100%; padding-right: 0; }

  .multiple-card .add-money-account { max-width: 100%; width: 100%; }
  .hide-on-mob { display: none !important;}
  .show-on-mob { display: block !important;}

  .show-on-mob .creator-benefits {  margin: 0;}
  .show-on-mob .btn-block, .show-on-mob .btn-block span.submit { margin-top: 0;}

  .gallery-img.mob-margin-off { margin-bottom: 0; padding-bottom: 0; }


  body.dark-mode section.footer { background-color: #161616; }
  
  body.dark-mode .copyright p, body.dark-mode section.footer ul span { color: #fff;}

  .profile-gallery-img .swiper-button-prev, .profile-gallery-img .swiper-button-next {display: none;}

  .my-account-detail span.logout-btn, section.header span.log-out-btn { font-size: 12px; padding: 8px 16px;}

  .profile-content { padding-right: 70px !important; }
  span.chat-tab { width: 70px; height: 70px; }

  .earning-inner .left-block h2 { font-size: 20px; }
  .profile-states .setting-item { padding: 10px 20px; }
  .setting-item-name { font-size: 12px!important;  line-height: 18px!important; }

  span.chat-tab {  width: 80px !important; height: 80px !important; }

  .videos-photos-item { width: 33.333% !important; }
  .videos-photos-block .videos-photos-item figure { margin-bottom: 10px !important; }
  .videos-photos-block .videos-photos-item figure img { width: 50%; }
  .videos-photos-block .videos-photos-item label { font-size: 12px !important; }

  .profile-content h1 { font-size: 16px !important; margin-bottom: 10px !important;}
  h3.share-link { margin: 0 0 5px; font-size: 14px; }

   
   
   .login-wrap .my-ac-form-info .form-inner-holder { margin-bottom: 0; }
   .login-wrap .form-block .new-user { margin-top: 0; }

   .heading-block.create_new { padding-bottom: 10px;}

   .notication-txt .form-inner-holder { margin-bottom: 0; }
   .my-ac-form-info .form-inner-holder.consumer-info-text.mt-5 { margin-top: 5px; }
   .chat-items .chat-list:first-child { padding-top: 0; }
   .profile-edit-block .profile-edit-inner { margin-bottom: 10px; }

   .earning-block { margin-bottom: 10px !important;}
   .add-money-wrap .form-inner-holder.consumer-info-text { margin: 0; }

   .add-money-block .add-money-heading { margin-top: 15px; }

   .add-money-block .btn-block { margin-top: 0; } 
   .add-money-block .btn-block span.submit { margin-top: 0; }

   .registration-wrap .my-ac-form-info.reg-nt .form-inner-holder { margin-bottom: 0; }

   .my_account-wrap .page-heading-block { padding-bottom: 10px; } 
   .my_account-wrap .my-ac-form-info { padding-bottom: 10px; } 
   .my_account-wrap .my-ac-form-info .form-inner-holder { margin-bottom: 5px; }

   .my-ac-top-wrap .my-account-content .ac-status-top { margin-bottom: 0; }

   .my-ac-top-wrap .my-account-content { padding-bottom: 10px; } 
   .my-ac-top-wrap .my-account-content .ac-status-top { margin-bottom: 0; }

   .my_account-wrap .notication-txt { margin-bottom: 10px; }
   .my_account-wrap .my-account-buttons { padding-bottom: 0; }

   .form-group { margin-bottom: 15px; }

   .earning-inner { padding: 20px 20px !important; }

   .new-card-wrap .heading-block h1 { width: 100% !important; }
   .data-filter-form .form-group { margin-bottom: 5px; }

   .chat-type-form button { top: 10px !important;}
   .chat-type-form .attach-file { top: 12px !important; }

   .model-box h3 { margin-bottom: 15px;}
   .modal-wrapper .form-group { padding-left: 0; padding-right: 0; }

   .receive-msg.more-content button { padding: 6px 10px; min-width: 60px; font-size: 12px; }
   .consumer-info-text .btn { height: 42px;}

   .video-photo-filter .search-filter-slide-inner { height: 70%; }
   .video-photo-filter-item { padding: 10px; }
   .video-photo-filter-item figure.icon img { width: 40px; }

   .search-filter-slide-inner .btn-block .reset-btn, .search-filter-slide-inner .btn-block .submit { height: 42px; }
   .login-wrap { min-height: calc(100vh - 135px);} 


   .my-wallet.earning-inner { flex-direction: column;}
   .my-wallet.earning-inner .right-block { padding-top: 14px;}
   .my-wallet .right-block .icon-box.withdraw-btn { width: 100%; justify-content: center;}

   .compressionlevel { width: 100%; margin: 0 0 10px;}
   .compress-wrap button.submit { max-width: 100%; }

   .upoad-detail .progress { width: 76%;}

}


@media (max-width: 560px) {
  .consumer-onboarding-wrap { padding-bottom: 120px !important; }
}

/* -=-=-=-=-=-=-= MEDIA QUERY / RESPONSIVE CSS -=-=-=-=-=-=-= */
