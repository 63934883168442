*,
::after,
::before {
    box-sizing: border-box
}

* {
    outline: none;
}

@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@300;400;500;600;700;800&display=swap');

body {
    margin: 0;
    font-family: 'Gothic A1', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #140A1B;
    background-color: #FAFAFA;
    background-image: url('../images/bg_splash.svg');
    background-size: cover;
    padding-top: 84px;
}
a { color: #4C164D;}
.static-content ul { 
    list-style-type: disc; 
    list-style-position: inside; 
 }
 .static-content li {
    display: list-item;
}
.static-content h1 {
    font-size:26px ;
}
.static-content h2 {
    font-size:22px ;
}
.static-content h3 {
    font-size:20px ;
}
.static-content h4 {
    font-size: 18px;
}
.static-content h5 {
    font-size: 16px;
}
.static-content h6 {
    font-size: 14px;
}

.static-content p { font-size: 16px; line-height: 24px;}
img {
    max-width: 100%
}

figure,
h1,
h2,
h3,
h4,
h6,
img,
li,
p,
section,
ul {
    margin: 0;
    padding: 0
}

.container {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    min-height: calc(100vh - 176px);
    display: flex;
    border: 1px solid #dbdbdb;
}

.wrapper {
    padding-top: 0px;
    padding-bottom: 0px;
}

.uploaded-btn-block .submit {
    margin: 0 auto;
}

.uploaded-btn-block.btn-block {
    margin: 20px auto;
    padding-left: 0;
    padding-right: 0;
}

@media (max-width:768px) {
    .container {
        max-width: 100%;
        min-height: 100vh;
    }
}

@media (max-width:768px) {
    .txt-format {
        text-align: center;
    }
}

.wrap {
    width: 100%;
}

a,
span {
    font-family: 'Gothic A1', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #4C164D;
    letter-spacing: 0.01em;
    text-decoration: none;
}

input,
select {
    height: 52px;
    width: 100%;
    border-radius: 26px;
    background-color: rgba(230, 225, 231, 0.6);
    border: none;
    padding: 5px 24px;
    font-family: 'Gothic A1', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #9C8E9E;
}

select {
    background-image: url('../images/select-arrow.png');
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-position: right 13px center;
    padding-right: 34px;
}

.btn-block {
    position: relative;
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 40px;
}

input.submit,
.submit {
    background-color: #4C164D;
    color: #FFFFFF;
    font-family: 'Gothic A1', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    height: 52px;
    width: 100%;
    border-radius: 26px;
    border: none;
    padding: 5px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 10px 30px 2px rgba(76, 22, 77, 0.1);
    max-width: 375px;
    margin: 20px auto;
}

span.reset-btn {
    background-color: #fff;
    color: #4C164D;
    font-family: 'Gothic A1', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    height: 52px;
    width: 100%;
    border-radius: 26px;
    border: none;
    padding: 5px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #4C164D;
}

.form-group {
    margin-bottom: 20px;
}

.form-block {
    padding: 0 20px;
    width: 100%;
}

.pass-input {
    position: relative;
}
.hide-pass {
    background-image: url("../images/password-icon.png");
    top: 17px;
    right: 15px;
}

.show-pass {
 background-image: url("../images/ic_eye_close.png");
}

.pass-input span {
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 20px;
    height: 20px;
    z-index: 1;
    right: 10px;
    top: 15px;
}

.pass-input span img {
    display: none;
}

.pass-input span.hide-pass .hide-pass-icon {
    display: block;
}

.pass-input span.show-pass .show-pass-icon {
    display: block;
}

.heading-block {
    display: flex;
    align-items: center;
    padding: 20px 20px;
}

.heading-block h1 {
    font-family: 'Gothic A1', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    width: calc(100% - 80px);
    color: #140A1B;
}

.form-group:last-of-type {
    margin-bottom: 0;
}

ul {
    list-style: none;
}

input:focus-visible {
    outline: none;
}

.registration-wrap {
    width: 100%;
}

.col-7 {
    width: 60%;
    padding: 0 7.5px;
}

.col-5 {
    width: 40%;
    padding: 0 7.5px;
}

.row {
    display: flex;
    margin: 0 -7.5px;
    flex-wrap: wrap;
}

.row .form-group:last-of-type {
    margin-bottom: 15px;
}

*::-webkit-input-placeholder {
    /* Edge */
    opacity: 1;
    color: #9C8E9E;
    font-family: 'Gothic A1', sans-serif;
}

*:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    opacity: 1;
    color: #9C8E9E;
    font-family: 'Gothic A1', sans-serif;
}

*::placeholder {
    opacity: 1;
    color: #9C8E9E;
    font-family: 'Gothic A1', sans-serif;
}

textarea {
    height: 140px;
    border-radius: 15px;
    background: rgba(230, 225, 231, 0.6);
    width: 100%;
    border: none;
    padding: 18px 20px;
    resize: none;
    font-family: 'Gothic A1', sans-serif;
    color: #9C8E9E;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
}

.col-6 {
    width: 50%;
    padding: 0 7.5px;
}

.request-purchased-wrap {
    width: 100%;
}

.heading-block .filter-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 1px solid #E6E1E7;
}

/*...........................desktop.........................*/
.heading-block .align-left {
    text-align: left;
}

.header .desktop-logo {
    width: 125px;
}

.header-container {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .desktop-logo img {
    width: 100%;
}

.header {
    background-color: #fff;
    padding: 7px;
    margin-bottom: 30px;
    border-bottom: 1px solid #dbdbdb;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
}

section.header span.log-out-btn {
    border: 2px solid #4C164D;
    padding: 8px 22px;
    border-radius: 50px;
}

.logo-block img.desktop-logo {
    width: 100%;
    max-width: 200px;
}

.logo-block img.desktop-logo {
    width: 100%;
    max-width: 250px;
}

.logo-block span {
    width: 100%;
    text-align: center;
}

section.footer ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

section.footer ul span {
    font-weight: 500;
    margin: 0px 14px;
    font-size: 12px;
    color: #fff;
}

.copyright {
    text-align: center;
    margin-top: 12px;
    margin-bottom: 15px;
}

.copyright p {
    font-size: 12px;
    color: #fff;
    font-weight: 500;
}

section.footer {
    margin-top: 25px;
    display: inline-block;
    width: 100%;
    background-color: transparent;
    padding: 0px 15px;
    position: relative;
}

@media (max-width:768px) {
    body {
        background-image: none;
        padding-top: 0px;
    }

    .footer-container ul li {
        text-align: center;
        padding: 5px;
    
    }

    section.footer ul {
        display: inline-block;
        width: 100%;
    }

    section.footer {
        margin-top: 0;
        padding-top: 15px;
    }

    .header {
        margin-bottom: 0;
        border-bottom: 1px solid #dbdbdb;
    }

    section.footer {
        margin-top: 0;
        background-color: #FAFAFA;
        padding-top: 15px;
    }

    .container {
        min-height: calc(100vh - 136px);
        border-left: none;
        border-right: none;
    }

    .btn-block {
        margin-top: 25px;
    }

    .copyright p,
    section.footer ul span {
        color: #4C164D;
    }
}

@media (min-width:768px) {
    .container {
        background-color: transparent;
        border: none;
    }

    .container>div {
        background-color: #fff;
        height: 100%;
        align-self: center;
        min-height: 65vh;
    }

    .container.small-container {
        max-width: 375px;
    }
}

span {
    cursor: pointer;
}

.loader-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgb(76 22 77 / 80%);
    z-index: 9;
    display: none;
    margin: 0 auto;
    right: 0;
    bottom: 0;
}
.loader-wrap.display-block{
    display: block;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #4C164D;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    min-height: auto;
    position: relative;
    top: calc(50% - 60px);
    left: calc(50% - 60px);
}

.container {
    position: relative;
}

.btn-loader {
    border: 4px solid #fff;
    border-radius: 50%;
    border-top: 4px solid #beb9b9;
    position: absolute;
    right: 9px;
    top: 14px;
    width: 22px;
    height: 22px;
    border-width: 4px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    display: none;
    left: auto;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.space-between {
    justify-content: space-between;
}

.heading-block h1.left-align {
    text-align: left;
}

span.filter-link {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E6E1E7;
    border-radius: 100%;
}

h1.large-heading {
    font-size: 18px;
    line-height: 22.5px;
}

input.search-submit-btn {
    height: 20px;
    width: 20px;
    padding: 0;
    background-image: url('../images/searh-icon.svg');
    background-color: transparent;
    position: absolute;
    left: 20px;
    top: 16px;
    cursor: pointer;
}

.searh-block {
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
}

input.search-box {
    padding-left: 45px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, .3);
    border-radius: 10px;
    background-color: #fff;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #CA4AB5
}

/*......................main-end....................................*/
.splash-wrap {
    background-image: url("../images/bg_splash.svg");
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-size: cover;
    padding-bottom: 30px;
}

.splash-wrap img {
    max-height: 75vh;
    max-width: 225px;
}

.wrapper.splash {
    padding: 0;
}

.wrapper.splash .container {
    min-height: 100vh;
    position: fixed;
    width: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    max-width: 100%;
}

.container>div.splash-wrap {
    height: auto;
    align-self: auto;
}

@media (min-width:768px) {
    .splash-wrap img {
        max-height: 75vh;
        max-width: 100%;
        min-width: 300px;
    }
}

/*.....................................*/
.benfits-block {
    width: 100%;
    padding-bottom: 60px;
    position: relative;
}

.creator-benefits {
    padding: 0 20px;
}

.creator-benefits h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    font-family: 'Gothic A1', sans-serif;
    margin-bottom: 16px;
    color: #140A1B;
    padding-left: 35px;
}

.creator-benefits ul {
    margin-bottom: 40px;
}

.creator-benefits ul li {
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #140A1B;
    padding-left: 36px;
    margin-bottom: 20px;
    position: relative;
}

.creator-benefits .submit {
    margin-top: 74px;
}

@media (min-width:768px) {
    .creator-benefits h3 {
        padding-left: 35px;
    }
}

/*.................404-not-found...............................*/
.page-not-found-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
}

.page-not-found-inner h1 {
    font-size: 100px;
    font-weight: bold;
    background: linear-gradient(45deg, #d97edc, #270428);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 108px;
}

.page-not-found-inner h4 {
    font-size: 20px;
    color: #938f8f;
    margin-bottom: 8px;
}

.page-not-found-inner p {
    font-size: 14px;
    margin-bottom: 16px;
    font-weight: 500;
    color: #000;
    line-height: 24px;
}

.page-not-found-inner span {
    background-color: #4C164D;
    color: #FFFFFF;
    font-family: 'Gothic A1', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    height: 52px;
    width: 100%;
    border-radius: 26px;
    padding: 5px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 254px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
}

.page-not-found-inner {
    max-width: 392px;
}

/*........................my-account.............................*/
.my_account-wrap .chat-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
}

.my_account-wrap {
    width: 100%;
}

.my-account-img-block {
    padding: 0 20px;
}

.my-account-img-block figure {
    position: relative;
    padding-top: 100%;
    border-radius: 15px;
    overflow: hidden;
}

.my-account-img-block img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.my-account-content {
    padding: 24px 20px;
    border-bottom: 4px solid #E6E1E7;
}

.my-account-content h2 {
    font-size: 20px;
    font-weight: 800;
    line-height: 25px;
    letter-spacing: 0.01em;
    text-align: left;
}

.my-account-content h5 {
    margin: 18px 0 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #140A1B;
}

.my-account-content p {
    color: #6F7378;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
}

.my-account-detail-item.edit-profile .img-icon img {
    max-height: 20px;
}

.my-account-detail-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.my-account-detail-item .left-content {
    display: flex;
    align-items: center;
}

.my-account-detail-item .left-content .img-icon {
    margin-right: 20px;
}

.my-account-detail-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #E6E1E7;
}

.my-account-detail-item .item-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #140A1B;
}

span.logout-btn {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #CA4AB5;
    display: table;
    margin: 20px auto;
    padding: 0 20px;
}

.my-account-detail {
    padding-top: 3px;
}

.my-account-img-block figure::after {
    content: '';
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.profile-edit-inner figure {
    position: relative;
    height: 80px;
    width: 80px;
    overflow: hidden;
    border-radius: 100%;
}

.profile-edit-block {
    padding: 0 20px;
}

.profile-edit-inner {
    background: linear-gradient(0deg, #4C164D, #7B1F7C);
    border-radius: 15px;
    display: flex;
    padding: 20px;
    margin-bottom: 20px;
}

.profile-edit-content {
    padding-left: 20px;
    width: calc(100% - 80px);
}

.profile-edit-content h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 6px;
}

.profile-edit-content .mail-link {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FFFFFFCC;
    margin-bottom: 0px;
    display: block;
}

.profile-edit-content .edit-btn {
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #140A1B;
    background: #FFFFFF;
    border-radius: 17px;
    padding: 8px 21px 6px;
    display: flex;
    max-width: 120px;
    align-items: center;
    margin-top: 15px;
}

.profile-edit-content .edit-btn i {
    margin-top: 0px;
    vertical-align: top;
    margin-right: 7px;
}

.my_account-wrap {
    position: relative;
    padding-bottom: 62px;
}

.chat-bar {
    border-top: 1px solid #E6E1E7;
}

.edit-profile-wrap {
    width: 100%;
}

.upload-img {
    position: relative;
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
}

.upload-img figure {
    overflow: hidden;
    border-radius: 100%;
    width: 130px;
    height: 130px;
    position: relative;
}

.upload-img figure img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
}

.upload-img .upload-icon {
    position: absolute;
    right: -7px;
    bottom: -7px;
}

.upload-img .upload-icon input[type="file"] {
    background-color: transparent;
    width: 44px;
    height: 44px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.edit-content {
    padding: 0 20px;
}

.info-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #140A1B;
    padding-left: 22px;
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    max-width: calc(100% - 40px);
    position: relative;
}

i.info-icon {
    position: absolute;
    left: 0;
    top: 1px;
}

.edit-profile-wrap {
    padding-bottom: 24px;
}

.add-money-block,
.address-block {
    padding: 0 20px;
}

input.add-money {
    padding-left: 47px;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #140A1B;
    height: 66px;
    border-radius: 33px;
}

i.dollar-symbol {
    position: absolute;
    top: 19px;
    left: 20px;
}

.add-money-filed {
    position: relative;
}

input.add-money::-webkit-input-placeholder {
    /* Edge */
    opacity: 1;
    color: #140A1B;
}

input.add-money:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    opacity: 1;
    color: #140A1B;
}

input.add-money::placeholder {
    opacity: 1;
    color: #140A1B;
}

.add-money-heading h3,
.address-heading h3 {
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #140A1B;
}

span.add-money-btn,
.address-btn {
    font-size: 12px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    left: 235px;
    border: 1px solid #4C164D;
    border-radius: 17px;
    align-items: center;
    justify-content: center;
    padding: 6px 17px;
}

.add-money-heading,
.address-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.add-money-block.bank-withdrawl .add-money-heading {
    margin-top: 0;
}

.address-heading {
    margin-top: 0;
}

.withdraw-amount-inner .select-cotent {
    position: relative;
    top: auto;
    left: auto;
}

.select-cotent input[type="checkbox"] {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    position: relative;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
}

.select-cotent label::before {
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #E6E1E7;
}

.select-cotent input[type="checkbox"]:checked+label::before,
.radio-select input[type="radio"]:checked+label::before {
    background-color: #4C164D;
    border-color: #4C164D;
    background-image: url("../images/complete-icon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 13px;
}

.add-money-account-top,
.address-account-top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.add-money-account-top h5,
.address-account-top h5 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-left: 12px;
}

.add-money-account-top img {
    display: block;
}

.card-number-block {
    display: flex;
    justify-content: space-between;
}

.card-number-block .card-number {
    color: #140A1B;
    font-size: 8px;
    display: flex;
    align-items: center;
}

.card-number-block .card-number .show {
    color: #140A1B;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
}

p.card-number span {
    color: #140A1B;
    font-size: 8px;
    display: flex;
    align-items: center;
    margin-right: 6px;
}

.card-detail>label,
.card-holder-name>label,
.address-detail .left-block label {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #6F7378;
    margin-bottom: 4px;
    display: block;
}

.add-money-account,
.address-account {
    border: 2px solid #E6E1E7;
    border-radius: 12px;
    padding: 17px;
    margin-bottom: 15px;
}

.address-detail {
    display: flex;
    justify-content: space-between;
}

.address-detail .select-cotent {
    align-self: flex-end;
}

.add-money-wrap,
.address-wrap {
    padding-bottom: 100px;
    position: relative;
    width: 100%;
}

.address-block .btn-block {
    position: absolute;
    bottom: 25px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 20px;
}

.add-money-account.active,
.address-account.active {
    border-color: #4C164D;
}

.manage-card-block {
    padding: 0 20px;
}

.card-holder-name h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #140A1B;
}

.card-holder-name {
    margin-bottom: 18px;
}

i.trash-icon {
    margin-left: auto;
    position: absolute;
    right: 1px;
    top: 1px;
}

.manage-card-block .add-money-account-top {
    position: relative;
    padding-right: 22px;
}

.modal-wrapper {
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 100vh;
    position: fixed;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.model-inner {
    background-color: #fff;
    width: 100%;
    border-radius: 20px;
    padding: 36px 0px;
    text-align: center;
}

.delete-model .btn-block {
    display: flex;
    max-width: none;
    margin-left: -7.5px;
    margin-right: 7.5px;
    width: calc(100% + 15px);
    margin-top: 25px;
}

.btn-block .btn-inner {
    display: contents;
    position: relative;
}

.model-inner.delete-model {
    padding: 36px 27.5px;
}

.model-inner.delete-model .btn-block .reset-btn,
.model-inner.delete-model .btn-block .submit {
    margin: 0 7.5px;
}

.model-box h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.01em;
    text-align: center;
    margin-bottom: 30px;
}

figure.delete-icon {
    width: 90px;
    height: 90px;
    border-radius: 100%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
}

.delet-content {
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #000000;
    margin-bottom: 55px;
    padding: 0 15px;
}

.delet-content span {
    color: #000;
    font-size: 18px;
}

.new-card-wrap {
    width: 100%;
}

.new-card-wrap .form-row {
    display: flex;
    margin-left: -7.5px;
    margin-right: -7.5px;
}

.new-card-form {
    padding: 0 20px 25px;
}

.setting-wrap {
    width: 100%;
}

.setting-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E6E1E7;
    padding: 15px 20px;
}

.setting-item-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.01em;
    max-width: calc(100% - 21px);
}

.change-password-wrap {
    width: 100%;
}

.change-password-inner {
    padding: 0 20px 25px;
}

.manage-subscription-wrap {
    width: 100%;
    position: relative;
    padding-bottom: 142px;
}

.manage-subscription-inner {
    padding: 0 20px 25px;
}

.manage-subscription-inner ul li {
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #140A1B;
    padding-left: 36px;
    margin-bottom: 20px;
    position: relative;
}

.plan-wrap {
    background: linear-gradient(180deg, #901893 0%, #4C164D 100%);
    border-radius: 20px;
    padding: 25px 20px;
}

.paln-top {
    display: flex;
    justify-content: space-between;
}

.paln-top h2 {
    font-size: 30px;
    font-weight: 800;
    line-height: 38px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #FFFFFF;
}

.paln-top .plan-heading span {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FFFFFF;
}

.plan-left-block p {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #fff;
    margin-top: 10px;
}

.plan-heading {
    display: flex;
    align-items: flex-end;
}

.paln-top .active-btn {
    font-size: 11px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.01em;
    text-align: center;
    background: #47EA75;
    align-self: flex-start;
    border-radius: 12px;
    padding: 0px 13px;
    align-items: center;
}

.plan-bottom p {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #ffff;
}

.plan-bottom p span {
    font-size: 12px;
    font-weight: 800;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #fff;
    margin-left: 5px;
}

.plan-bottom {
    text-align: center;
    padding-top: 13px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 15px;
}

.plan-wrap {
    background: linear-gradient(180deg, #901893 0%, #4C164D 100%);
    border-radius: 20px;
    padding: 25px 20px 13px;
    margin-bottom: 30px;
}

.paln-top .plan-heading span {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    margin-bottom: 6px;
    margin-left: 3px;
    color: #fff;
}

.payment-btn {
    background-color: #fff;
    color: #4C164D;
    font-family: 'Gothic A1', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    height: 52px;
    width: 100%;
    border-radius: 26px;
    border: 1px solid #4C164D;
    padding: 5px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.manage-subscription-inner .btn-block .btn-inner {
    display: block;
}

.manage-subscription-inner .btn-block {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
}

.widthdrawl-item label {
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: left;
}

.widthdrawl-item span {
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.01em;
    margin-left: 6px;
}

.widthdrawl-item {
    text-align: left;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.widthdrawl-block {
    padding-bottom: 10px;
    padding-top: 10px;
}

.add-new-bank-wrap {
    width: 100%;
}

.add-new-bank-inner {
    padding: 0 20px 25px;
}

.add-new-address-inner {
    padding: 0 20px 25px;
}

.add-new-address-inner p.info {
    font-family: Gothic A1;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    margin-top: 24px;
    position: relative;
}

.manage-card-block .select-cotent {
    margin-left: auto;
}

.withdraw-amount-wrap {
    width: 100%;
    position: relative;
    padding-bottom: 95px;
}

.withdraw-amount-inner {
    padding: 0 20px 25px;
}

.radio-select input[type="radio"] {
    width: 20px;
    height: 20px;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

.radio-select label::before {
    content: '';
    width: 20px;
    height: 20px;
    display: block;
    border: 1px solid #4C164D;
    border-radius: 100%;
}

.radio-select {
    position: relative;
}

.withdraw-amount-item .radio-select {
    align-self: flex-start;
    margin-top: 2px;
}

.withdraw-amoun-right {
    width: calc(100% - 20px);
    padding-left: 17px;
}

.withdraw-amoun-right h4 {
    color: #4C164D;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 12px;
    opacity: 0.5;
}

.withdraw-amoun-right .amount-block {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-align: left;
    opacity: 0.5;
}

.withdraw-amount-item {
    display: flex;
    padding: 20px;
    background-color: #E6E1E7;
    border-radius: 15px;
    margin-bottom: 15px;
}

.withdraw-amount-item.active h4,
.withdraw-amount-item.active .amount-block {
    opacity: 1;
}

.payment-type-item p {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #4C164D;
    min-width: 150px;
}

.payment-type-item {
    display: flex;
    align-items: center;
    margin: 16px 0;
}

.withdraw-amount-inner h2 {
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-top: 37px;
    margin-bottom: 27px;
}

.payment-type-block {
    border: 2px solid #4C164D;
    border-radius: 15px;
    padding: 6px 25px;
}

.withdraw-amount-wrap .btn-block {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
}

.card-holder-name.address-item {
    padding-left: 36px;
}

@media (max-width:768px) and (min-width:480px) {
    .delet-content {
        margin-bottom: 15px;
    }

    figure.delete-icon {
        margin-bottom: 10px;
    }

    .model-box h3 {
        margin-bottom: 8px;
    }

    .model-inner.delete-model {
        padding: 25px 27.5px;
    }
}

@media (min-width: 768px) {
    .model-inner {
        max-width: 580px;
    }

    .modal-wrapper {
        max-width: 100%;
    }
}

@media (max-width:768px) {
    input.add-money {
        font-size: 20px;
        background-position: 20px 21px;
    }
}

@media (max-width:415px) {
    .manage-subscription-inner .btn-block {
        padding-left: 25px;
        padding-right: 25px;
    }

    .withdraw-amount-wrap .btn-block {
        padding-left: 20px;
        padding-right: 20px;
    }
}

/*......................................chat.....................................*/
.chat-wrap {
    width: 100%;
    padding: 0px;
}

.chat-list {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E6E1E7;
    padding: 18px 20px;
}

.chat-img figure {
    display: flex;
    border-radius: 100%;
    overflow: hidden;
    width: 44px;
    height: 44px;
    position: relative;
}

.chat-img figure img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
}

.chat-box-inner h3.price {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-align: left;
}

.chat-img {
    position: relative;
    width: 44px;
}

.chat-items {
    height: calc(100vh - 312px);
    overflow: auto;
}

.chat-img span {
    width: 13px;
    height: 13px;
    position: absolute;
    border-radius: 100%;
    border: 2px solid #fff;
    bottom: 0;
    right: 0;
}

.chat-img span.online {
    background-color: #4ACA6E;
}

.chat-detail {
    width: calc(100% - 44px);
    padding-left: 15px;
}

.chat-wrap h1 {
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.01em;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 0 20px;
}

.chat-detail h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 3px;
}

.chat-detail p {
    color: #6F7378;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: left;
    max-width: 175px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.chat-img span.offline {
    background-color: #9C8E9E;
}

.chat-box-wrap {
    width: 100%;
    text-align: center;
}

.heading-block .chat-img figure {
    width: 40px;
    height: 40px;
}

.heading-block .chat-img {
    width: 40px;
}

.chat-box-inner {
    width: 100%;
    padding: 0 20px;
    height: calc(100vh - 341px);
    overflow: auto;
}

.sent-msg {
    display: table;
    max-width: 80%;
    margin-left: auto;
    background: #4C164D;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: right;
    color: #fff;
    padding: 15px 26px;
    border-radius: 30px 0px 30px 30px;
    margin-bottom: 20px;
}

.receive-msg {
    margin-right: auto;
    display: table;
    max-width: 80%;
    background-color: #E6E1E7;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    padding: 15px 26px;
    border-radius: 0px 30px 30px 30px;
    margin-bottom: 20px;
}

.chat-type-box {
    display: flex;
    align-items: center;
    padding: 15px 20px 15px 10px;
}

.chat-type-form {
    position: relative;
}

.chat-type-in {
    width: calc(100% - 52px);
}

.chat-type-form a.attach-file {
    position: absolute;
    z-index: 1;
    right: 60px;
    top: 15px;
}

.chat-type-form button {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 10px;
    top: 14px;
}

.chat-type-form input[type="text"] {
    background: rgba(230, 225, 231, 0.4);
    border: 1px solid #9C8E9E;
    padding-right: 84px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
}

.receive-msg.more-content {
    border-radius: 0 15px 15px 15px;
    line-height: 24px;
}

.sent-msg.more-content {
    border-radius: 15px 0 15px 15px;
    line-height: 24px;
}

.sent-msg.more-content.img-sent img {
    border-radius: 10px;
    display: block;
}

.sent-msg.more-content.img-sent {
    padding: 10px;
}

.chat-box-inner span.play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    cursor: pointer;
}

.chat-box-inner figure.video-tab {
    position: relative;
    border-radius: 10px;
    display: block;
    overflow: hidden;
}

.model-inner input.submit {
    margin-top: 25px;
}

.model-box .radio-select {
    display: flex;
    align-items: center;
}

.model-box .radio-select {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    margin-bottom: 8px;
}

.model-box input[type="radio"] {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    position: relative;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
    margin-left: 5px;
    margin-right: 3px;
    margin-top: 3px;
}

.model-box .radio-select:last-child {
    margin-bottom: 0;
}

.model-box input[type="radio"]:checked+label::before {
    background-color: #4C164D;
    background-image: url("../images/complete-icon.png");
    background-repeat: no-repeat;
    background-size: 72%;
    background-position: center;
}

.model-box input[placeholder="Price"] {
    padding-left: 52px;
}

.modal-wrapper .form-group {
    padding-left: 48px;
    padding-right: 48px;
}

.modal-btn-block {
    padding-left: 48px;
    padding-right: 48px;
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
}

.modal-wrapper .form-group.texarea {
    padding-left: 15px;
    padding-right: 15px;
}

.upload-wrap {
    padding: 0 20px;
}

.upload-file-tab {
    border: 2px dashed #4C164D;
    background-color: #E6E1E7;
    border-radius: 15px;
    height: 200px;
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-bottom: 20px;
}

.upload-file-tab figure {
    margin-bottom: 24px;
}

.upload-file-tab input[type="file"] {
    position: absolute;
    height: 100% !important;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 2;
}

.upload-content p {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 18px;
}

.upload-content span {
    width: 150px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #4C164D;
    color: #fff;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
}

.upload-process figure {
    display: flex;
    overflow: hidden;
    border-radius: 5px;
    width: 52px;
    height: 52px;
    position: relative;
}

.upoad-detail {
    width: calc(100% - 52px);
    padding-left: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.upload-detail-left {
    width: calc(100% - 20px);
}

.upoad-detail h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    margin: 0;
}

.upload-detail-left p {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #6F7378;
    margin-top: 3px;
}

.progress {
    margin: 10px 0 0;
    padding: 0;
    width: 86%;
    height: 6px;
    background: #E6E1E7;
    border-radius: 6px;
    position: relative;
}

.bar {
    float: left;
    min-width: 1%;
    height: 100%;
    background: #4C164D;
    border-radius: 10px 0 0 10px;
}

.upload-process {
    display: flex;
    align-items: flex-start;
    padding: 18px 0;
    border-bottom: 1px solid #E6E1E7;
}

.percent {
    position: absolute;
    top: -5.5px;
    font-family: tahoma, arial, helvetica;
    font-size: 12px;
    color: #4C164D;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: right;
    right: -45px;
}

.chat-box-wrap.main-upload-block {
    padding-bottom: 0;
}

.chat-type-form .attach-file {
    position: absolute;
    right: 54px;
    top: 15px;
}

.btn-block .btn-block-in {
    position: relative;
}

.modal-wrapper.report-user-modal .form-group.texarea {
    padding-left: 20px;
    padding-right: 20px;
}

.Price-request {
    position: relative;
}

i.dollar-symbol {
    position: absolute;
    top: 15px;
    left: 20px;
}

.chat-detail.chat-time {
    width: 100px;
}

.chat-detail.chat-time p {
    padding: 0;
    text-align: right;
}

.chat-detail.chat-middle {
    width: calc(100% - 144px);
}

@media (max-width:768px) {
    .chat-box-inner {
        height: calc(100vh - 311px);
    }

    .modal-wrapper {
        max-width: 100%;
        min-height: 100vh;
    }

    .model-inner input.submit {
        max-width: 100%;
    }
}

@media(min-width:768px) {
    .chat-box-inner {
        min-height: 390px;
    }

    .model-inner {
        max-width: 580px;
    }

    .chat-box-wrap {
        position: relative;
        padding-bottom: 86px;
    }

    .chat-box-wrap .chat-type-box {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
}

@media (max-width:420px) {
    .uploaded-btn-block.btn-block {
        padding-left: 20px;
        padding-right: 20px
    }

    .uploaded-btn-block.btn-block .btn-loader {
        right: 30px;
    }

    .modal-btn-block {
        padding-left: 20px;
        padding-right: 20px;
    }
}

/*..............................................search...............................*/
.inner-uploaded figure {
    height: 100%;
}

.inner-uploaded {
    height: 100%;
    border-radius: 15px;
    position: relative;
}

.uploded-imges .col-6 {
    margin-bottom: 15px;
}

.setup-profile-wrap.photo-video-wrap {
    width: 100%;
}

.inner-uploaded input[type="checkbox"] {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    position: relative;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
}

.inner-uploaded input[type="checkbox"]:checked+label::before {
    background-color: #CA4AB5;
    border-color: #CA4AB5;
    background-image: url("../images/complete-icon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 13px;
}

.select-cotent {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.setup-profile-wrap.photo-video-wrap>p {
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.01em;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
}

.setup-profile-wrap.photo-video-wrap .bottom-bar {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
}

.setup-profile-wrap.photo-video-wrap .bottom-bar .left-block h4 {
    font-size: 20px;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #CA4AB5;
}

.setup-profile-wrap.photo-video-wrap .bottom-bar .left-block p {
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
}

.setup-profile-wrap.photo-video-wrap .bottom-bar span {
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-color: #4C164D;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: center;
    border: 1px solid #4C164D;
    box-shadow: 0px 10px 30px 2px rgba(76, 22, 77, 0.1);
}

.inner-uploaded figure::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.gallery-img {
    padding: 0 20px;
    width: 100%;
}

.chat-box-wrap.content-library {
    width: 100%;
    position: relative;
    padding-bottom: 0;
}

.chat-box-wrap.content-library h1 {
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.01em;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 0 20px;
}

.chat-box-wrap.content-library.my-folder .content-library-box-list {
    height: calc(100vh - 288px);
    overflow: auto;
}

.setup-profile-wrap.photo-video-wrap.full-pages {
    position: relative;
}

.message-btn {
    position: absolute;
    bottom: 0;
    right: 16px;
    border-radius: 100%;
}

.setup-profile-wrap.photo-video-wrap.full-pages .gallery-img {
    height: calc(100vh - 240px);
}

.right-block .btn-loader {
    top: 8px;
    right: 3px;
}

.setup-profile-wrap.photo-video-wrap .right-block {
    position: relative;
}

.chat-box-wrap.content-library.my-folder .chat-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.chat-box-wrap.content-library.my-folder {
    padding-bottom: 60px;
}

.chat-bar ul li a {
    padding: 15px 0;
    display: inline-block;
    width: 100%;
    max-width: 55px;
    position: relative;
    filter: brightness(0) invert(60%);
}

.chat-bar ul li a.active {
    filter: none;
}

.text-type-in {
    position: absolute;
    bottom: 15px;
    left: 15px;
    font-size: 12px;
    font-weight: 900;
    line-height: 12px;
    letter-spacing: 0.01em;
    background-color: #fff;
    padding: 6px 9px 5px;
    border-radius: 12px;
    min-width: 32px;
    min-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #CA4AB5;
    cursor: default;
}

.setup-profile-wrap.search-wrap {
    position: relative;
    width: 100%;
    padding-bottom: 0;
}

.search-filter-slide {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 100%;
    display: flex;
    align-items: flex-end;
    background-color: rgba(0, 0, 0, 0.4);
    justify-content: center;
    z-index: 99;
}

.search-filter-slide-inner {
    width: 100%;
    background-color: #fff;
    padding: 25px 20px;
    border-radius: 25px 25px 0 0;
}

.search-filter-slide-inner h2 {
    font-family: 'Gothic A1', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.01em;
    text-align: center;
    margin-bottom: 22px;
}

.search-filter-slide-inner select {
    margin-bottom: 15px;
}

.enter-type {
    margin-top: 12px;
    display: flex;
    padding-left: 0px;
}

.enter-type p {
    background-color: rgba(202, 74, 181, 0.2);
    height: 30px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 8px;
    margin: 0 3px;
}

.enter-type p span {
    margin-left: 6px;
    line-height: 10px;
}

.search-filter-slide-inner .btn-block {
    display: flex;
}

.search-filter-slide-inner .btn-block .reset-btn,
.search-filter-slide-inner .btn-block .submit {
    margin: 0 7.5px;
}

.search-filter-slide-inner .btn-block {
    display: flex;
    max-width: none;
    margin-left: -7.5px;
    margin-right: 7.5px;
    width: calc(100% + 15px);
    margin-top: 25px;
}

/*.................................price-range-slider.........................*/
.price-range-heading h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
}

.price-range-slider {
    width: 100%;
    margin-bottom: 30px;
}

.price-range-slider .range-value {
    margin: 0;
}

.price-range-slider .range-value input {
    width: auto;
    background: none;
    color: #4C164D;
    box-shadow: none;
    height: auto;
    min-height: 40px;
    max-width: 70px;
    border: 1px solid #E6E1E7;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    padding: 2px 0 0;
}

.price-range-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    align-items: center;
}

.price-range-slider .range-bar {
    border: none;
    background: #E6E1E7;
    height: 6px;
    width: calc(100% - 40px);
    margin-left: 10px;
    border-radius: 5px;
    position: relative;
}

.price-range-slider .range-bar::after {
    content: "";
    background-color: #E6E1E7;
    height: 6px;
    width: 10%;
    position: absolute;
    right: -4.1%;
    top: 0;
    border-radius: 0 5px 5px 0;
}

.price-range-slider .range-bar .ui-slider-range {
    background: #4C164D;
}

.price-range-slider .range-bar .ui-slider-handle {
    border: none;
    border-radius: 25px;
    background: #DB8ACE;
    border: 8px solid #4C164D;
    height: 22px;
    width: 36px;
    top: -8px;
    cursor: pointer;
}

.price-range-slider .range-bar .ui-slider-handle+span {
    background: #DB8ACE;
}

.ui-slider {
    position: relative;
    text-align: left;
}

.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 1.2em;
    height: 1.2em;
    cursor: default;
    -ms-touch-action: none;
    touch-action: none;
}

.ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: .7em;
    display: block;
    border: 0;
    background-position: 0 0;
}

.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
    filter: inherit;
}

.ui-slider-horizontal {
    height: .8em;
}

.ui-slider-horizontal .ui-slider-handle {
    top: -.3em;
    margin-left: -.6em;
}

.ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%;
}

.ui-slider-horizontal .ui-slider-range-min {
    left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
    right: 0;
}

.ui-slider-vertical {
    width: .8em;
    height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
    left: -.3em;
    margin-left: 0;
    margin-bottom: -.6em;
}

.ui-slider-vertical .ui-slider-range {
    left: 0;
    width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
    bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
    top: 0;
}

/*.........................end.........................*/
.related-result {
    width: 100%;
    max-width: 100%;
    overflow: auto;
    display: grid;
}

.related-result .row-1 {
    display: flex;
}

.gallery-img .uploded-imges .related-result .col-6 {
    width: 170px;
}

.related-result .row-1 .inner-uploaded {
    width: 155px;
}

.related-top h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 20px;
    padding-right: 38px;
    position: relative;
}

.related-top h3 .right-arrow {
    position: absolute;
    right: 0;
    top: 2px;
}

.related-top {
    margin-top: 25px;
}

.request-inner figure {
    position: relative;
    height: 80px;
    width: 80px;
    overflow: hidden;
    border-radius: 100%;
}

.request-block {
    padding: 0 20px;
}

.request-inner {
    background: linear-gradient(0deg, #4C164D, #7B1F7C);
    border-radius: 15px;
    display: flex;
    padding: 20px;
    margin-bottom: 25px;
}

.request-content {
    padding-left: 20px;
    width: calc(100% - 80px);
}

.request-content h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 6px;
}

.request-content p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FFFFFFCC;
    margin-bottom: 8px;
}

.request-content span.request-btn {
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #140A1B;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 8px 13px;
}

.video-photo-filter-item .select-cotent {
    position: relative;
    top: auto;
    left: auto;
}

.video-photo-filter-item .select-cotent input[type="checkbox"] {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    position: relative;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
}

.video-photo-filter-item .select-cotent label::before {
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #E6E1E7;
}

.video-photo-filter-item .select-cotent input[type="checkbox"]:checked+label::before {
    background-color: #4C164D;
    border-color: #4C164D;
    background-image: url("../images/complete-icon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 13px;
}

.video-photo-filter-item .left-block {
    display: flex;
    align-items: center;
    width: calc(100% - 30px);
}

.video-photo-filter-item .left-block label {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-left: 20px;
    color: #121212;
}

.video-photo-filter-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    border: 2px solid #E6E1E7;
    border-radius: 10px;
    margin-bottom: 15px;
}

.btn-block .btn-inner {
    display: contents;
    position: relative;
}

.btn-block .btn-inner .btn-loader {
    right: 16px;
}

.request-inner figure img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
}

.container div.ReactTags__tagInput {
    width: 100%;
}

.container div.ReactTags__tagInput input.ReactTags__tagInputField,
.container div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 52px;
    width: 100%;
    border-radius: 26px;
    background-color: rgba(230, 225, 231, 0.6);
    border: none;
    padding: 5px 24px;
    font-family: 'Gothic A1', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #9C8E9E;
}

.container div.ReactTags__selected span.ReactTags__tag {
    border: none;
    background-color: rgba(202, 74, 181, 0.2);
    height: 30px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 8px;
    margin: 0 3px 10px;
}

.container button.ReactTags__remove {
    background-color: #4C164D;
    width: 14px;
    height: 14px;
    display: block;
    border-radius: 100%;
    color: #fff;
    padding: 0;
    line-height: 5px;
    margin-left: 6px;
}

.ReactTags__selected {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
}

.container .ReactTags__suggestions>* {
    border-radius: 3px;
    color: #fff !important;
    box-shadow: none;
    border: 1px solid #4c164D;
    width: auto;
    padding: 2px 6px;
}

.consumer-onboarding-wrap {
    width: 100%;
    padding-top: 20px;
    position: relative;
    padding-bottom: 110px;
}

.consumer-onboarding-bottom p {
    font-size: 20px;
    font-weight: 800;
    line-height: 34px;
    letter-spacing: 0.01em;
    text-align: center;
}

.consumer-onboarding-bottom {
    position: absolute;
    bottom: 25px;
    padding-left: 20px;
    padding-right: 20px;
}

.consumer-onboarding-bottom .btn-block {
    margin-top: 20px;
}

.consumer-onboarding-bottom {
    position: absolute;
    bottom: 25px;
    padding-left: 20px;
    padding-right: 20px;
    background: linear-gradient(0deg, #fff, rgba(255, 255, 255, 0.9), transparent);
    padding-top: 80px;
    width: 100%;
}

.consumer-onboarding-bottom.card-info {
    position: relative;
    bottom: 25px;
    padding-left: 20px;
    padding-right: 20px;
    background: linear-gradient(0deg, #fff, rgba(255, 255, 255, 0.9), transparent);
    padding-top: 20px;
    width: 100%;
}

@media (min-width:768px) {
    .consumer-onboarding-wrap .gallery-img .uploded-imges .col-6 {
        width: 25%;
    }

    .content-library-box {
        width: calc(50% - 10px);
    }

    .content-library-box-list {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .chat-box-wrap.content-library.my-folder .content-library-box-list {
        height: auto;
    }

    .setup-profile-wrap.photo-video-wrap.full-pages .gallery-img .col-6 {
        width: 33.333%;
    }

    .setup-profile-wrap.photo-video-wrap.full-pages .gallery-img {
        height: calc(100vh - 308px);
    }
}

@media (max-width:768px) {
    .chat-box-wrap.content-library.my-folder .content-library-box-list {
        height: calc(100vh - 337px);
    }

    .inner-uploaded figure {
        border-radius: 15px;
    }

    .text-type-in {
        position: absolute;
        bottom: 10px;
        left: 10px;
    }

    .setup-profile-wrap.photo-video-wrap.full-pages .gallery-img {
        height: calc(100vh - 288px);
        min-height: auto;
    }

    .gallery-img .uploded-imges .related-result .col-6 {
        width: 155px;
    }

    .related-result .row-1 .inner-uploaded {
        width: 140px;
    }

    .searh-block {
        margin-right: 20px;
        margin-bottom: 25px;
    }
}

@media (max-width:768px) and (min-width:580px) {
    .chat-box-wrap.content-library.my-folder .content-library-box-list {
        height: auto;
    }

    .setup-profile-wrap.photo-video-wrap.full-pages .gallery-img {
        height: auto;
    }

    .gallery-img {
        height: auto;
    }
}

@media (max-width:560px) {
    .consumer-onboarding-wrap {
        padding-bottom: 140px;
    }
}

input.search-submit-btn {
    font-size: 0;
}

.search-filter-slide .multi-range-slider .bar {
    height: 6px;
    border-radius: 5px;
    background-color: #E6E1E7;
}

.search-filter-slide .multi-range-slider .bar-left,
.search-filter-slide .multi-range-slider .bar-right {
    background-color: transparent;
    box-shadow: none;
    border-radius: unset;
}

.search-filter-slide .multi-range-slider .bar-inner {
    background-color: #4C164D;
    box-shadow: none;
}

.search-filter-slide .multi-range-slider .thumb {
    border: none;
    border-radius: 25px;
    background: #DB8ACE;
    border: 8px solid #4C164D;
    height: 22px;
    width: 36px;
    top: -8px;
    cursor: pointer;
}

.search-filter-slide .multi-range-slider .thumb::before {
    display: none
}

.search-filter-slide .multi-range-slider .thumb .caption * {
    background-color: #4C164D;
}

/*..........................content-library..........................*/
.library-inner {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px 25px;
    width: 100%;
    align-items: center;
}

.chat-box-wrap.content-library p {
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
}

.content-library-box-list {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
}

.content-library-box {
    position: relative;
    margin-bottom: 25px;
}

.content-library-box figure {
    border-radius: 8%;
    overflow: hidden;
    width: 100%;
    display: flex;
    position: relative;
}

.library-inner .name-tab {
    font-size: 30px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #fff;
}

.library-inner .Title {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
}

.library-inner .Title::before {
    content: '';
    width: 5px;
    height: 5px;
    background-color: #fff;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    margin-left: 15px;
}

.library-inner .next-link:before {
    content: '';
    border-bottom: 2px solid #FFFFFF;
    border-right: 2px solid #FFFFFF;
    width: 12px;
    height: 12px;
    display: inline-block;
    transform: rotate(-45deg);
}

.library-inner .next-link {
    margin-left: auto;
}

.content-library-box figure::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.90), transparent);
}

.content-library-box:last-child {
    margin-bottom: 0;
}

.content-library-box figure img {
    width: 100%;
}

.heading-block .upload-link {
    width: 40px;
    height: 40px;
    background-color: #4C164D;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner-uploaded {
    height: 100%;
    border-radius: 15px;
    position: relative;
}

.uploded-imges .col-6 {
    margin-bottom: 15px;
}

.setup-profile-wrap.photo-video-wrap {
    width: 100%;
}

.inner-uploaded input[type="checkbox"] {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    position: relative;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
}

.select-cotent label::before {
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: 2px solid #fff;
    position: absolute;
    left: 0;
    top: 0;
    background-color: transparent;
}

.payment-type-item .select-cotent label::before {
    background-color: #E6E1E7;
}

.inner-uploaded input[type="checkbox"]:checked+label::before {
    background-color: #CA4AB5;
    border-color: #CA4AB5;
    background-image: url("../images/complete-icon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 13px;
}

.select-cotent {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.setup-profile-wrap.photo-video-wrap>p {
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.01em;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
}

.setup-profile-wrap.photo-video-wrap .bottom-bar {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
}

.setup-profile-wrap.photo-video-wrap .bottom-bar .left-block h4 {
    font-size: 20px;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #CA4AB5;
}

.setup-profile-wrap.photo-video-wrap .bottom-bar .left-block p {
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
}

.setup-profile-wrap.photo-video-wrap .bottom-bar span {
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-color: #4C164D;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: center;
    border: 1px solid #4C164D;
    box-shadow: 0px 10px 30px 2px rgba(76, 22, 77, 0.1);
}

.inner-uploaded span.play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    cursor: pointer;
    min-width: 56px;
    min-height: 56px;
}

.inner-uploaded figure::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.gallery-img {
    padding: 0 20px;
    height: calc(100vh - 304px);
    overflow: auto;
}

.chat-box-wrap.content-library {
    width: 100%;
    position: relative;
}

.chat-box-wrap.content-library h1 {
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.01em;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 0 20px;
}

.chat-bar ul {
    display: flex;
    justify-content: space-between;
}

.chat-bar ul li {
    flex: auto;
    text-align: center;
}

.chat-bar ul li span {
    padding: 15px 0;
    display: inline-block;
    width: 100%;
    max-width: 55px;
    position: relative;
    filter: brightness(0) invert(60%);
}

.chat-bar ul li span.active {
    filter: none;
}

.chat-bar ul li span.active::before {
    content: '';
    background-color: #CA4AB5;
    height: 3px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 7px 7px;
}

.chat-box-wrap.content-library.my-folder .content-library-box-list {
    height: calc(100vh - 288px);
    overflow: auto;
}

.setup-profile-wrap.photo-video-wrap.full-pages {
    position: relative;
}

.message-btn {
    position: absolute;
    bottom: 0;
    right: 16px;
    border-radius: 100%;
}

.setup-profile-wrap.photo-video-wrap.full-pages .gallery-img {
    height: calc(100vh - 240px);
}

.right-block .btn-loader {
    top: 8px;
    right: 3px;
}

.setup-profile-wrap.photo-video-wrap .right-block {
    position: relative;
}

.chat-box-wrap.content-library.my-folder .chat-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.chat-box-wrap.content-library.my-folder {
    padding-bottom: 60px;
}

@media (min-width:768px) {
    .content-library-box {
        width: calc(50% - 10px);
    }

    .content-library-box-list {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .chat-box-wrap.content-library.my-folder .content-library-box-list {
        height: auto;
    }

    .setup-profile-wrap.photo-video-wrap.full-pages .gallery-img .col-6 {
        width: 33.333%;
    }

    .setup-profile-wrap.photo-video-wrap.full-pages .gallery-img {
        height: calc(100vh - 308px);
    }

    .gallery-img .uploded-imges .col-6 {
        width: 33.333%;
    }

    .gallery-img,
    .setup-profile-wrap.search-wrap .gallery-img#scrollableDiv {
        padding: 0 20px;
        height: calc(130vh - 372px);
        overflow: auto;
    }

    .setup-profile-wrap.search-wrap .gallery-img .uploded-imges .col-6 {
        width: 25%;
    }

    .setup-profile-wrap.search-wrap .gallery-img {
        height: auto;
    }

    .consumer-onboarding-wrap .gallery-img {
        height: auto;
    }
}

@media (max-width:768px) {
    .setup-profile-wrap.search-wrap .gallery-img#scrollableDiv {
        min-height: calc(130vh - 372px);
    }

    .chat-box-wrap.content-library.my-folder .content-library-box-list {
        height: calc(100vh - 337px);
    }

    .setup-profile-wrap.photo-video-wrap.full-pages .gallery-img {
        height: auto;
        min-height: auto;
    }

    .gallery-img {
        height: auto;
        padding-bottom: 15px;
        overflow: auto;
    }
}

@media (max-width:768px) and (min-width:580px) {
    .chat-box-wrap.content-library.my-folder .content-library-box-list {
        height: auto;
    }

    .setup-profile-wrap.photo-video-wrap.full-pages .gallery-img {
        height: auto;
    }

    .gallery-img {
        height: auto;
    }
}

/*.......................my-folder........................*/
.heading-block.create_new h1 {
    text-align: left;
    width: auto;
}

.folder-mid-content {
    padding: 0 20px;
    min-height: 402px;
}

.heading-block.create_new {
    justify-content: space-between;
}

.crate-new {
    color: #4C164D;
    border: 1px solid #4C164D;
    font-family: 'Gothic A1', sans-serif;
    font-size: 12px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    width: 102px;
    height: 34px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.videos-photos-block .videos-photos-item figure {
    background: #E6E1E7;
    border-radius: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 100%;
    margin-bottom: 20px;
}

.videos-photos-block .videos-photos-item label {
    font-family: 'Gothic A1', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    width: 100%;
    display: block;
}

.videos-photos-block .videos-photos-item figure img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.videos-photos-item {
    width: 25%;
    padding: 10px;
}

.videos-photos-block {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}

.folder-mid-content p {
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.01em;
    margin-bottom: 20px;
}

.modal-wrapper {
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 100vh;
    position: fixed;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    z-index: 9991;
}

.model-inner {
    background-color: #fff;
    width: 100%;
    border-radius: 20px;
    padding: 36px 0px;
    text-align: center;
}

.model-box h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.01em;
    text-align: center;
    margin-bottom: 30px;
}

.model-box .radio-select label::before {
    content: '';
    height: 16px;
    width: 16px;
    position: absolute;
    border-radius: 100%;
    left: -19px;
    border: 1px solid #4C164D;
    top: 2px;
}

.model-box input[placeholder="Price"],.setup-profile-wrap .model-box input , .add-money-filed {
    background-image: url('../images/dollar-symbol.png');
    background-repeat: no-repeat;
    background-position: left 23px center;
    padding-left: 52px;
}

.model-box .radio-select label {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    color: rgba(76, 22, 77, 0.5);
    position: relative;
    min-width: 90px;
    padding-left: 6px;
}

.modal-wrapper .form-group {
    padding-left: 48px;
    padding-right: 48px;
}

input,
select {
    height: 52px;
    width: 100%;
    border-radius: 26px;
    background-color: rgba(230, 225, 231, 0.6);
    border: none;
    padding: 5px 24px;
    font-family: 'Gothic A1', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #9C8E9E;
}

.model-inner input.submit {
    max-width: 100%;
}

.modal-wrapper.create-folder .modal-btn-block {
    padding-left: 48px;
    padding-right: 48px;
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
}

.container.folder-block .chat-box-wrap {
    position: relative;
    padding-bottom: 60px;
}

.container.folder-block .chat-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9;
}

@media (max-width:768px) {
    .chat-items {
        height: calc(100vh - 219px);
    }

    .modal-wrapper {
        max-width: 100%;
        min-height: 100vh;
    }

    .folder-mid-content {
        padding: 0 20px;
        min-height: calc(100vh - 216px);
    }

    body.bg-page .folder-mid-content {
        min-height: calc(100vh - 264px);
    }

    .folder-mid-content {
        padding: 0 20px;
        min-height: calc(100vh - 270px);
    }
}

@media (max-width:620px) {
    .videos-photos-item {
        width: 50%;
    }
}

@media(min-width:768px) {
    .modal-wrapper {
        max-width: 100%;
    }

    .model-inner {
        max-width: 580px;
    }
}

@media (max-width:420px) {
    .modal-wrapper.create-folder .modal-btn-block {
        padding-left: 20px;
        padding-right: 20px;
    }
}

/*..................setup-profile..........................*/
.steps {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    color: #929292;
    height: auto;
}

.steps>.step {
    position: relative;
    text-align: center;
    font-size: 0.875rem;
    color: #6D6875;
    flex: auto;
}

.steps>.step:before {
    content: attr(data-step);
    display: block;
    margin: 0 auto;
    background: #ffffff;
    border: 2px solid #E6E1E7;
    color: #4C164D;
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 1.9rem;
    border-radius: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.01em;
    text-align: center;
}

.steps>.step:after {
    content: "";
    position: absolute;
    display: block;
    background: #e6e6e6;
    width: 100%;
    height: 0.125rem;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
}

.steps>.step:last-child:after {
    display: none;
}

.steps>.step.is-complete {
    color: #6D6875;
}

.steps>.step.is-active:before {
    color: #FFF;
    border: 2px solid #4C164D;
    background: #4C164D;
    margin-bottom: -4.9rem;
}

.steps>.step.is-complete:before {
    content: "";
    color: #fff;
    background: #4C164D;
    border-color: #4C164D;
    background-image: url("../images/complete-icon.png");
    background-position: center;
    background-repeat: no-repeat;
}

.steps>.step.is-complete.is-active:after {
    background: #4C164D;
}

.steps>.step.is-active {
    font-size: 1.5rem;
}

.setup-profile-wrap {
    width: 100%;
    position: relative;
}

.step-bar {
    margin-bottom: 30px;
    flex-grow: 0;
    padding-left: 15px;
    padding-right: 15px;
}

.setup-profile-wrap .heading-block {
    flex-grow: 0;
}

.setup-profile-wrap {
    display: flex;
    flex-flow: column;
    padding-bottom: 30px;
}

.setup-profile-wrap .form-block form .btn-block {
    flex-grow: 0;
}

.setup-profile-wrap .form-block form {
    height: 100%;
    display: flex;
    flex-flow: column;
}

.setup-profile-wrap .form-block {
    flex-grow: 1;
}

.upload-img figure {
    overflow: hidden;
    border-radius: 100%;
    width: 130px;
    height: 130px;
    position: relative;
}

.upload-img {
    position: relative;
}

.upload-img .upload-icon {
    position: absolute;
    right: -7px;
    bottom: -7px;
}

.upload-img {
    position: relative;
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
}

.main-profile-content {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    margin-bottom: 0px;
}
.automate-message .main-profile-content {
    margin-bottom: 10px;
}

.enter-type p {
    background-color: rgba(202, 74, 181, 0.2);
    height: 30px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 8px;
    margin: 0 3px;
}

.enter-type p span {
    margin-left: 6px;
    line-height: 10px;
}

.form-group .enter-type {
    margin-top: 12px;
    display: flex;
    padding-left: 0px;
}

.form-group-content p {
    font-size: 10px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #6F7378;
}

.form-group-content h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 5px;
}

.form-group-content {
    margin-bottom: 8px;
}

.upload-text p {
    position: relative;
    padding-left: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
}

.upload-text p img {
    position: absolute;
    left: 0;
    top: 4px;
}

.setup-profile-wrap .upload-text {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 15px;
}

.inner-uploaded {
    height: 100%;
    border-radius: 10%;
    position: relative;
    overflow: hidden;
}

.inner-uploaded input[type="file"],
.browse-block input[type="file"] {
    height: 100%;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
}

.uploded-imges .col-6 {
    margin-bottom: 15px;
}

.inner-uploaded figure .upload-link {
    position: absolute;
    right: 0;
    bottom: 0;
}

.col-conent {
    font-size: 20px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0.01em;
    text-align: center;
    margin-top: 15px;
}

.uploded-imges.last-step {
    margin-top: 0px;
}

.inner-uploaded figure .upload-link {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 52px;
    height: 52px;
}

.upload-img figure img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

p.info {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 20px;
    margin-top: 20px;
    max-width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

p.info i.info-icon {
    position: absolute;
    left: 0;
    top: 0;
}

@media (min-width:768px) {

    .uploded-imges.last-step.library-block .col-6,
    .uploded-imges .col-6 {
        width: 50%;
    }

    .container.small-container .setup-profile-wrap {
        align-self: center;
    }

    .setup-profile-wrap .form-block form .all-input {
        flex-grow: 1;
    }
}

/*..............................profile-select..............................*/
.profile-select-wrap {
    width: 100%;
    position: relative;
}

.top-profile-block figure img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 7%;
}

.top-profile-block figure {
    overflow: hidden;
    width: 100%;
    position: relative;
    padding-top: 100%;
    border-radius: 0 0 30px 30px;
}

.top-profile-block {
    position: relative;
}

.top-profile-block span.top-arrow {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
    top: 20px;
    left: 20px;
}

.profile-content {
    padding: 25px 20px;
}

.profile-content h1 {
    font-size: 20px;
    font-weight: 800;
    line-height: 25px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 15px;
}

.profile-content h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 5px;
}

.profile-content p {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #6F7378;
}

.inner-uploaded {
    height: 100%;
    border-radius: 15px;
    position: relative;
}

.inner-uploaded figure {
    display: flex;
    position: relative;
    border-radius: 10%;
    overflow: hidden;
    padding-top: 112%;
}

.inner-uploaded figure img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.profile-gallery-img .col-6 {
    margin-bottom: 15px;
    width: 25%;
}

.profile-gallery-img {
    padding: 0 20px;
}

.premium-block {
    padding: 20px;
}

.premium-block-inner {
    background: linear-gradient(180deg, #901893 0%, #4C164D 100%);
    border-radius: 20px;
    padding: 37px 25px;
}

.premium-block-inner h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #fff;
    margin-bottom: 25px;
    border-bottom: 1px solid #fff;
    display: table;
    margin-left: auto;
    margin-right: auto;
}

.premium-block-inner p {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #fff;
    margin-bottom: 10px;
}

.premium-block-inner h5 {
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #fff;
    margin: 20px 0 15px;
}

.premium-block-inner li {
    font-size: 15px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #fff;
    margin-bottom: 8px;
    position: relative;
    padding-left: 38px;
}

.list-icon {
    position: absolute;
    left: 0;
    top: 3px;
}

.profile-select-wrap span.submit {
    margin-bottom: 40px;
    margin-top: 15px;
}

.profile-select-wrap .btn-block {
    margin: 0 auto;
    padding: 0 20px;
}

.top-profile-block figure::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), transparent);
}

span.chat-tab {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100px;
    height: 100px;
}

@media (max-width:768px) {
    .profile-gallery-img .col-6 {
        width: 50%;
    }

    .top-profile-block figure {
        padding-top: 70%;
    }

    .profile-gallery-img .col-6 {
        width: 50%;
    }

    .registraion-successful .btn-block {
        padding-left: 15px;
        padding-right: 15px;
    }
}

/*..................................register..............................*/
.login-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 25px;
    align-self: center;
    width: 100%;
    position: relative;
}

.logo-block {
    width: 100%;
    padding: 37px 15px;
    display: inline-block;
    text-align: center;
}

.form-block span.forgot-pass {
    text-align: right;
    margin-left: auto;
    display: table;
}

.form-block .new-user {
    text-align: center;
    font-family: 'Gothic A1', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #140A1B;
    margin-top: 30px;
}

.form-block .new-user span {
    text-align: left;
    padding-left: 5px;
    text-decoration: none;
}

.forgot-pass-wrap {
    width: 100%;
    padding-bottom: 35px;
}

/*......................benfits......................*/
.creator-benefits {
    padding: 0 20px;
}

.creator-benefits h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    font-family: 'Gothic A1', sans-serif;
    margin-bottom: 16px;
    color: #140A1B;
}

.creator-benefits ul {
    margin-bottom: 40px;
}

.registration-wrap {
    width: 100%;
    align-self: flex-start;
    padding-bottom: 20px;
    position: relative;
}

.registration-wrap p {
    padding: 0 20px;
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: left;
}

.content-account ul {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin: 0 -7.5px;
}

.content-account ul li>span {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #9C8E9E;
    background-color: #FCF7FD;
    height: 100px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #E6E1E7;
    width: 100%;
}

.content-account ul li {
    width: 50%;
    padding: 0 7.5px;
}

.content-account {
    padding: 0 20px;
    margin-bottom: 12px;
}

.content-account ul li>span.active {
    color: #140A1B;
    border-color: #CA4AB5;
}

.content-account ul li p {
    padding: 0;
    margin: 0;
}

.content-account ul li p > span {
    margin-top: 14px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-items: flex-start;
    line-height: 1;
}

.content-account ul li p span span {
    margin-right: 5px;
    margin-top: 0; 
    height: 11px;
    line-height: 1;
    flex:0 0 11px;
    transform: translateY(0px);
}

.content-account ul li p a span img {
    margin-top: 2px;
}

.registration-wrap .heading-block {
    padding-bottom: 20px;
}

.registration-wrap .form-block .new-user {
    margin-top: 20px;
}

.registration-wrap .btn-block {
    margin-top: 35px;
}

input#react-select-2-input {
    height: auto;
}

.registraion-successful {
    padding: 30px 20px;
    text-align: center;
    background: #FCF7FD;
    width: 100%;
}

.successful-img {
    margin-bottom: 20px;
}

.registraion-successful h2 {
    font-size: 19px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.01em;
    text-align: center;
}

.success-block h2 {
    margin-bottom: 10px;
}

.success-block h2:last-child {
    margin-bottom: 15px;
}

.registraion-successful p {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.01em;
    text-align: center;
}

.registraion-successful>figure {
    margin-top: 17px;
}

.container.small-container .forgot-pass-wrap {
    align-self: center;
    position: relative;
}

.successful-img {
    text-align: center;
}

.registraion-successful {
    padding: 20px 20px 100px;
    position: relative;
}

.registraion-successful .btn-block {
    position: absolute;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
}

.registraion-successful h2 {
    font-size: 18px;
    line-height: 22px;
}

.registraion-successful p {
    font-size: 15px;
    line-height: 22px;
}

.successful-img img {
    max-width: 205px;
    margin-left: auto;
    margin-right: auto;
}

.container.small-container .registraion-successful {
    align-self: center;
}

.container>div.registraion-successful {
    background: #FCF7FD;
}

.container.small-container .forgot-pass-wrap .form-group {
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width:768px) {
    .login-wrap {
        align-self: flex-start;
    }

    .logo-block a {
        width: auto;
        text-align: center;
        display: table;
    }

    .container.small-container .forgot-pass-wrap {
        align-self: flex-start;
        min-height: calc(100vh - 136px);
    }

    .container.small-container .registraion-successful {
        align-self: auto;
    }
}

@media (min-width:768px) {
    .registration-wrap .form-block>form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .registration-wrap .form-block .new-user {
        margin-top: 20px;
        width: 100%;
    }

    .registration-wrap .form-block>form .form-group {
        width: 49%;
    }

    .container.small-container .forgot-pass-wrap {
        min-height: auto;
    }
}

@media (max-width:375px) {
    .registraion-successful .btn-block {
        padding-left: 20px;
        padding-right: 20px;
    }
}

/*..................................request-purchased..........................*/
.view-request-wrap {
    width: 100%;
    padding: 0px;
}

.view-request-list {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E6E1E7;
    padding: 18px 20px;
}

.view-request-img figure {
    display: flex;
    border-radius: 100%;
    overflow: hidden;
    width: 44px;
    height: 44px;
    position: relative;
}

.view-request-img figure img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
}

.view-request-box-inner h3.price {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-align: left;
}

.view-request-img {
    position: relative;
    width: 44px;
}

.view-request-img span {
    width: 11px;
    height: 11px;
    position: absolute;
    border-radius: 100%;
    border: 2px solid #fff;
    bottom: 0;
    right: 0;
}

.view-request-img span.online {
    background-color: #4ACA6E;
}

.view-request-detail {
    width: calc(100% - 44px);
    padding-left: 15px;
}

.view-request-wrap h1 {
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.01em;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 0 20px;
}

.view-request-detail h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 3px;
}

.view-request-detail p {
    color: #6F7378;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: left;
    max-width: 175px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.view-request-img span.offline {
    background-color: #9C8E9E;
}

.view-request-bar {
    border-top: 1px solid #E6E1E7;
}

.view-request-bar ul {
    display: flex;
    justify-content: space-between;
}

.view-request-bar ul li {
    flex: auto;
    text-align: center;
}

.view-request-bar ul li a {
    padding: 15px 0;
    display: inline-block;
    width: 100%;
    max-width: 55px;
    position: relative;
    filter: brightness(0) invert(60%);
}

.view-request-bar ul li a.active {
    filter: none;
}

.view-request-bar ul li a.active::before {
    content: '';
    background-color: #CA4AB5;
    height: 3px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 7px 7px;
}

.view-request-items {
    height: calc(100vh - 220px);
    overflow: auto;
}

.view-request-box-wrap {
    width: 100%;
}

.heading-block .view-request-img figure {
    width: 40px;
    height: 40px;
}

.heading-block .view-request-img {
    width: 40px;
}

.view-request-box-inner {
    width: 100%;
    padding: 0 20px;
    height: calc(100vh - 272px);
    overflow: auto;
}

.view-request-inner p span {
    color: #6F7378;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
}

.request-purchased-wrap .view-request-items {
    height: auto;
}

.request-purchased-wrap .view-request-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.request-purchased-wrap .view-request-detail .price {
    font-size: 18px;
    font-weight: bold;
    color: #CA4AB5;
}

.request-purchased-wrap i {
    line-height: normal;
    font-style: normal;
    font-size: 6px;
    display: inline-block;
    vertical-align: middle;
}

.wrapper>.container {
    position: relative;
}

.data-filter-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    background-color: rgba(0, 0, 0, 0.5);
}

.data-filter-block {
    background-color: #fff;
    width: 100%;
    text-align: center;
    padding: 30px 25px;
    border-radius: 25px 25px 0 0;
    z-index: 9;
}

.data-filter-block h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #121212;
    margin-bottom: 30px;
}

.data-filter-form form {
    display: flex;
    flex-wrap: wrap;
}

#ui-datepicker-div {
    display: none;
    background-color: #fff;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
    margin-top: 0.25rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
    z-index: 999 !important;
}

.ui-datepicker-calendar thead th {
    padding: 0.25rem 0;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 400;
    color: #78909C;
}

.ui-datepicker-calendar tbody td {
    width: 2.5rem;
    text-align: center;
    padding: 0;
}

.ui-datepicker-calendar tbody td a {
    display: block;
    border-radius: 0.25rem;
    line-height: 24px;
    transition: 0.3s all;
    color: #4C164D;
    font-size: 12px;
    text-decoration: none;
    font-weight: normal;
}

.ui-datepicker-calendar thead th span {
    color: #CA4AB5;
    font-size: 12px;
}

.data-filter-form .datepicker {
    position: relative;
    display: inline-block;
    width: 100%;
}

.data-filter-form .datepicker::after {
    content: '';
    /* background-image: url('../images/calendar-icon.svg'); */
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

.data-filter-form .datepicker input {
    font-family: 'Gothic A1', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #9C8E9E;
}

.data-filter-form input::-webkit-input-placeholder {
    /* Edge */
    opacity: 1;
}

.data-filter-form input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    opacity: 1;
}

.data-filter-form input::placeholder {
    opacity: 1;
}

.data-filter-form .btn-block {
    margin-top: 20px;
}

.data-filter-form .form-group {
    width: 50%;
}

.ui-datepicker-calendar tbody td a:hover {
    background-color: #E0F2F1;
}

.ui-datepicker-calendar tbody td a.ui-state-active {
    background-color: #4C164D;
    color: white;
}

.ui-datepicker-header a.ui-corner-all {
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 2rem;
    height: 2rem;
    margin: 0.5rem;
    border-radius: 0.25rem;
    transition: 0.3s all;
}

.data-filter-form .form-group:first-child {
    padding-right: 7.5px;
}

.data-filter-form .form-group:nth-child(2) {
    padding-left: 7.5px;
}

.ui-datepicker-header a.ui-corner-all:hover {
    background-color: #ECEFF1;
}

.ui-datepicker-header a.ui-datepicker-prev {
    left: 0;
    /* background: url("../images/next-btn.svg"); */
    background-repeat: no-repeat;
    background-size: 0.5rem;
    background-position: 50%;
    transform: rotate(180deg);
}

.ui-datepicker-header a.ui-datepicker-next {
    right: 0;
    /* background: url('../images/next-btn.svg'); */
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 50%;
}

.ui-datepicker-header a>span {
    display: none;
}

.ui-datepicker-title {
    text-align: center;
    line-height: 2rem;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    padding-bottom: 0.25rem;
}

.ui-datepicker-week-col {
    color: #78909C;
    font-weight: 400;
    font-size: 0.75rem;
}

.earning-inner {
    background-image: url('../images/bg_earning_card_long.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 20px;
    overflow: hidden;
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
}

.earning-block {
    padding: 0 20px;
    margin-bottom: 20px;
}

.earning-inner .left-block h5 {
    margin: 0 0 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #fff;
}

.earning-inner .left-block h2 {
    font-size: 26px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #fff;
}

.earning-inner .right-block figure {
    max-width: 70px;
}

.filter-block {
    padding: 0px 20px 10px;
}

.filter-block .fiter-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 1px solid #E6E1E7;
}

.filter-block .filter-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}

.earning-inner .left-block {
    max-width: calc(100% - 64px);
}

.wallet-top-block {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.wallet-top-block h3 {
    max-width: calc(100% - 55px);
}

.wallet-bottom-block {
    justify-content: space-between;
    display: flex;
    margin-top: 3px;
}

.wallet-items .View-request-inner {
    width: 100%;
}

.wallet-top-block .price {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: right;
}

.wallet-top-block .price.debit {
    color: #D02020;
}

.wallet-top-block .price.credit {
    color: #119536;
}

.data-filter-form select {
    padding-right: 34px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    color: #9C8E9E;
}

.my-wallet.earning-inner .left-block {
    padding-left: 58px;
    position: relative;
}

.my-wallet .wallet-icon {
    position: absolute;
    left: 0;
    top: 0;
}

.request-purchased-wrap {
    position: relative;
}

.request-purchased-wrap.payment-history .view-request-detail .price {
    color: #4C164D;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: right;
}

.view-request-detail p.successful {
    color: #119536;
}

.view-request-detail p.cancelled {
    color: #D02020;
}

.payment-history .view-request-list:first-child {
    padding-top: 0;
}

.payment-history .view-request-items.wallet-items {
    margin-top: 10px;
}

/*............................new-css.........................*/
.card-detail .select-cotent,
.address-account .select-cotent {
    position: relative;
    top: auto;
    left: auto;
}

.model-box .dollar-symbol~input[placeholder="Price"] {
    background-image: none;
}

.add-money-block .add-money-filed .dollar-symbol {
    top: 20px;
}

.setup-profile-wrap.photo-video-wrap.full-pages {
    padding-bottom: 0;
}

.setup-profile-wrap.photo-video-wrap.full-pages .heading-block {
    padding-bottom: 20px;
}

.withdraw-amount-item.active .radio-select input:checked+label::after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #4C164D;
    position: absolute;
    left: 5.5px;
    top: 5.5px;
    border-radius: 100%;
    display: block;
}

.card-detail .select-cotent label::before,
.address-block .select-cotent label::before,
.add-money-account .select-cotent label::before {
    background-color: #E6E1E7;
}

.chat-btn-block span {
    background-color: #4C164D;
    color: #FFFFFF;
    font-family: 'Gothic A1', sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    width: 50%;
    border-radius: 26px;
    border: none;
    padding: 6px 12px;
    cursor: pointer;
    margin: 0px 5px;
    min-width: 90px;
}

.chat-btn-block {
    display: flex;
    margin: 10px -5px 0;
}

.chat-btn-block span.not-pay-btn {
    color: #4C164D;
    background-color: transparent;
    border: 1px solid #4C164D;
}

.arouzed-message {
    text-align: center;
    border: 1px solid #4C164D;
    border-radius: 10px;
    padding: 8px 5px;
    margin-bottom: 20px;
}

.chat-time span {
    font-size: 12px;
    margin: 0 2px;
}

.chat-time {
    display: table;
    margin-left: auto;
    margin-right: auto;
    background-color: #E6E1E7;
    padding: 5px 10px;
    border-radius: 18px;
    margin-bottom: 20px;
}

.chat-time span {
    font-size: 12px;
    margin: 0 2px;
    font-weight: 600;
}

.profile-edit-inner figure img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: top center;
}

@media (max-width:768px) {
    .view-request-items {
        height: calc(100vh - 136px);
        overflow: auto;
    }

    .report-user-modal .btn-block-in {
        padding-left: 15px;
        padding-right: 15px;
    }

    .data-filter-form .btn-block {
        margin-top: 0;
    }

    .modal-wrapper {
        max-width: 100%;
        min-height: 100vh;
        padding-top: 54px;
    }
}

@media (max-width:420px) {
    .request-purchased-wrap .view-request-detail .price {
        font-size: 16px;
    }

    .view-request-detail h3 {
        font-size: 13px;
    }

    .view-request-detail {
        padding-left: 9px;
    }
}

.chat-box-wrap.content-library p {
    margin-bottom: 0;
}

.chat-box-wrap.content-library .chat-detail.chat-time {
    background-color: transparent;
    margin-bottom: 0;
}

.my-wallet .right-block {
    cursor: pointer;
}

.withdraw-amoun-right .amount-block input[type="number"] {
    height: auto;
    padding: 0 7px;
}

.withdraw-amoun-right .amount-block {
    display: flex;
}

button.more-content.savepay {
    background-color: #4C164D;
    color: #fff;
    border: none;
    padding: 4px 22px;
    border-radius: 50px;
}

.favourite-icon .fav-btn {
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    background-image: url('../images/heart-regular.svg');
    background-size: 100% 100%;
    cursor: pointer;
}

.favourite-icon .fav-btn.active {
    background-image: url('../images/heart-solid.svg');
}

/*....................13-03-23.................*/
.request-report-wrapper .model-box span {
    display: table
}

.modal-wrapper.request-report-wrapper {
    display: none
}

.request-report-wrapper .model-box span {
    display: flex;
    align-items: center;
    font-size: 18px;
}

.modal-wrapper.request-report-wrapper.active .model-inner {
    padding: 20px;
    margin-bottom: 80px;
}

.modal-wrapper.request-report-wrapper {
    align-items: flex-end;
}

.modal-wrapper.request-amount-wrapper,
.modal-wrapper.report-user-wraper {
    display: none
}

.modal-wrapper.request-report-wrapper.active,
.modal-wrapper.request-amount-wrapper.active,
.modal-wrapper.report-user-wraper.active {
    display: flex;
}

.chat-btn-block span.option-btn {
    min-width: auto;
    color: #4C164D;
    background-color: transparent;
    border: 1px solid #4C164D;
    height: 31px;
    width: 31px;
    padding: 0;
    font-size: 22px;
    line-height: 34px;
}

.request-report-wrapper .model-box span.request-payment-btn {
    margin-bottom: 20px;
}

.request-report-wrapper .model-box span i {
    background-color: #4C164D;
    width: 35px;
    height: 35px;
    padding: 7px;
    border-radius: 100%;
    margin-right: 15px;
}

.chat-img-right {
    display: flex;
    align-items: center;
}

.chat-img-right span.profile-option-dot i {
    font-style: normal;
    font-size: 28px;
    margin-right: -6px;
}

.chat-img-right .profile-option-dot {
    margin-top: 8px;
}

/*....................end.................*/


.view-request-items.wallet-items {
    max-height: calc(100vh - 448px);
    min-height: 220px;
}
.receive-msg.more-content button {
    display: table;
    border-radius: 26px;
    border: none;
    padding: 8px 10px;
    min-width: 90px;
}
.reports-block {
    display: flex;
    padding: 0 10px;
}
.reports-block .earning-block {
    width: 50%;
    padding: 8px;
}
.view-more-btn {
    width: 100%;
    display: block;
    text-align: center;
    padding: 20px 10px;
}
.reports-block .earning-inner {
    padding: 12px 22px;
}
.reports-block .earning-block figure {
    min-height: 70px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.reports-block .earning-inner .left-block h5 {
    color: rgba(255,255,255,0.8);
}
.request-purchased-wrap.report-wrap .heading-block h1 {
    text-align: left;
}
.static-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 25px;
    text-align: left;
}

/* .benfits-block .creator-benefits ul li {
    text-align: center;
}
.benfits-block .creator-benefits h3 {
    padding-left: 0;
} */
@media (max-width:768px){
    .my-wallet.earning-inner .left-block h2 {
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0;
    }
   body .top-profile-block figure {
        padding-top: 100%;
    }
}

.agelaw span.confirm {
    font-size: 12px;
    margin-top: 6px;
    display: flex;
    text-align: left;
    color: #000;
}

.agelaw span.confirm input {
    height: 12px;
    width: auto;
}

.agelaw span.confirm label {line-height:20px;}

.agelaw {
    padding: 10px 0;
    width: 100%;
}

.blur-image {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 30px;
    margin-top: 10px;
}

.blur-image input{
	height: auto;
    width: auto;
}

.upload-img.blurred {
    filter: blur(3px);
}

.profile-states {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.profile-states input{
	height: auto;
    width: auto;
}
span.add-link {
    max-width: 40px;
}
.profile-states .setting-item {
    display: block;
}

@media (max-width: 767px) {
.ls-wrapper .logo-block { padding-top:1px; padding-bottom: 20px; }
}