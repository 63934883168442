.wrapper.main-content .row {
    width: 100%;
    margin: 0;
}
.main-content .splash-wrap figure {
    display: table;
    margin: 0 auto;
}
.main-content .main-inner li {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #fff;
    margin: 10px 0;
    text-align: left;
}
.main-content .main-inner li::before {
    content: '';
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    position: absolute;
    left: 0;
    top: 3px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
}
.main-content .main-inner li::after {
    content: '';
    position: absolute;
    border: 1px solid #fff;
    width: 15px;
    left: -2px;
    top: 8px;
}
.main-content .main-inner li {
    padding-left: 20px;
    position: relative;
}
.main-content .main-inner {
    max-width: 560px;
    margin: 0 auto;
}
.main-content .col-12 {
    width: 100%;
    padding: 0 7.5px;
}
.main-content h2 {
    color: #fff;
    max-width: 560px;
    margin: 30px auto 17px;
    padding: 0 8px;
}
.main-inner .form input, .main-inner .form select {
    background-color: #fff;
}
.main-inner .form input.submit {
    background: #4C164D;
    margin: 0;
}
.main-inner .form .btn-block {
    margin-top: 20px;
}
.main-content .splash-wrap figure {
    margin: 0 auto;
}
.main-content .left-block, .main-content .right-block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper.main-content .right-block {
    border-left: 2px solid #fff;
    padding: 25px 0;
}
.main-content .main-inner ul {
    padding: 0 8px;
}
.wrapper.main-content .splash-wrap {
    padding: 0;
}
.home-content .splash-wrap {
    align-items: normal;
}
.home-content.main-content .main-inner li {
    color: #000;
}
.home-content.main-content .main-inner li::before {
    border-top: 2px solid #000;
    border-right: 2px solid #000;
}
.main-content.home-content .main-inner li::after {
    border: 1px solid #000;
}
.main-content.home-content h2 {
    color: #4C164D;
}
.home-content .main-inner .form input, .home-content .main-inner .form select {
    border: 1px solid #4C164D;
}
.wrapper.main-content.home-content .right-block {
    border: none;
    background-color: #fff;
}
@media (max-width:768px){
    .home-content.wrapper.main-content .right-block {
        padding-top: 25px;
    }
    .main-content .splash-wrap {
        align-items: flex-start;
    }
	.main-content .left-block, .main-content .right-block  {width: 100%; padding: 25px; position: relative;}
	.wrapper.main-content .container {
    position: static;
    transform: none;
}
.wrapper.main-content .right-block {
    border-left: none;
    padding: 0 25px 25px;
}
.wrapper.main-content .right-block .col-6 {
    width: 100%;
}
body.landing-page {
    padding: 0;
}




}

.main-content h3 {
    color: #fff;
    margin: 30px auto 17px;
    max-width: 560px;
    padding: 0 8px;
}

.main-content a {
    color: #fff;
}
