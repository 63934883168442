.profile-select-wrap {
    width: 100%; position: relative;
}

.top-profile-block figure img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.top-profile-block figure {
    overflow: hidden;
    width: 100%;
    position: relative;
    padding-top: 38%;
    border-radius: 0 0 30px 30px;
}
.top-profile-block {
    position: relative;
}
.top-profile-block span.top-arrow {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
    top: 20px;
    left: 20px;
}
.profile-content {
    padding: 25px 20px;
}
.profile-content h1 {
    font-size: 20px;
    font-weight: 800;
    line-height: 25px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 15px;
}
.profile-content h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 5px;
}
.profile-content p {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #6F7378;
}
.inner-uploaded {
    height: 100%;
    border-radius: 15px;
    position: relative;
}
.inner-uploaded figure {
    display: flex;
    position: relative;
    border-radius: 10%;
    overflow: hidden;
    padding-top: 112%;
}
.inner-uploaded figure img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.profile-gallery-img .col-6 {
    margin-bottom: 15px;
    width:25%;
}
.profile-gallery-img {
    padding: 0 20px;
}

.premium-block {
    padding: 20px;
}
.premium-block-inner {
    background: linear-gradient(180deg, #901893 0%, #4C164D 100%);
    border-radius: 20px;
    padding: 37px 25px;
}
.premium-block-inner h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #fff;
    margin-bottom: 25px;
    border-bottom: 1px solid #fff;
    display: table;
    margin-left: auto;
    margin-right: auto;
}
.premium-block-inner p {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #fff;
    margin-bottom: 10px;
}
.premium-block-inner h5 {
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #fff;
    margin: 20px 0 15px;
}
.premium-block-inner li {
    font-size: 15px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #fff;
    margin-bottom: 8px;
    background-image: url('../images/list-arrow.svg');
    background-repeat: no-repeat;
    padding-left: 38px;
    background-position: 0 3px;
}
.profile-select-wrap span.submit {
    margin-bottom: 40px;
    margin-top: 15px;
}
.profile-select-wrap .btn-block {
    margin: 0 auto;
    padding: 0 20px;
}
.top-profile-block figure::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(0deg, rgba(0,0,0,0.4), transparent);
}
span.chat-tab {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100px;
    height: 100px;
}
@media (max-width:768px){
    .profile-gallery-img .col-6 {
        width: 50%;
    }
    .top-profile-block figure {
        padding-top: 70%;
    }
    .profile-gallery-img .col-6 {
        width:50%;
    }
}